// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: "1.00",
  type: 'DEV',
  api: {
    uri: "https://wblxmhswqg.execute-api.eu-central-1.amazonaws.com",
    path: "/dev",
  },
  amplify: {
    Auth: {
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_S68rv3s8M',
      userPoolWebClientId: '76p5j4tiimji5to3d4274ujm74',
      identityPoolId: 'eu-central-1:ffb9b461-8bec-4433-b38e-8d7cede6710b'
    },
    Storage: {
      AWSS3: {
        bucket: 'mgh-smartbox-assets',
        region: 'eu-central-1'
      }
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        appId: '04c9b3ab2efd4865a32a80e55baec804',
        region: 'us-east-1'
      },
      bufferSize: 1000,
      flushInterval: 5000,
      flushSize: 100,
      resendLimit: 5
    },
    Logger: {
      LOG_LEVEL: 'ERROR'
    }
  },

  maxDeltaMs: 300000, // = 5 mins (1000*60 = 1 minute)
  maxDeltaMt: 300,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
