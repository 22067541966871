import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { OverlayService } from 'src/app/services/overlay/overlay.service';
import { User } from 'src/app/utils/types';

@Component({
  selector: 'app-terms-conditions-modal',
  templateUrl: './terms-conditions-modal.component.html',
  styleUrls: ['./terms-conditions-modal.component.css']
})
export class TermsConditionsModalComponent implements OnInit {

  @Input('user') user: User

  public form: FormGroup;
  public saved: boolean = false;
  public userId: string;

  constructor(
    private api: ApiService,
    private overlay: OverlayService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.overlay.onLoading();
    const now = new Date().toISOString().split('T')[0];
    this.form = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      cf: ['', [Validators.required]],
      place: ['', [Validators.required]],
      date: [now, [Validators.required]],
      terms: [false, [Validators.requiredTrue]],
      marketing: ['', [Validators.required]],
      extraEu: ['', [Validators.required]],
    });
    this.form.controls.fullName.setValue(this.user.fullName ? this.user.fullName : '');
    this.overlay.offLoading();
  }

  public onSave() {
    this.saved = true;
    if (this.form.valid) {
      this.overlay.onLoading();
      const f = this.form.value;
      const u = { userId: this.user.userId, companyId: this.user.companyId, email: this.user.email, role: this.user.role, companies: this.user.companies, fullName: f.fullName, cf: f.cf, date: f.date, place: f.place, terms: f.terms, extraEu: f.extraEu, marketing: f.marketing }
      this.api.updateUser(u).subscribe(
        (r) => {
          this.overlay.closeModal();
          this.overlay.offLoading();
          location.reload();
        },
        (e: HttpErrorResponse) => {
          this.overlay.offLoading();
          if (e.message) {
            this.overlay.showToast({ icon: 'frown', color: 'orange', text: e.message });
            console.log(e.message);
          }
        },
      );
    }
  }

  public back() {
    this.overlay.closeModal();
  }

}
