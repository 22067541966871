<div class="flex h-full w-full">
  <!-- Sidebar always visible on Desktop -->
  <div *ngIf="layout === 'desktop'">
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
  </div>

  <div class="w-full h-full" [class.ml-56]="layout === 'desktop'">
    <!-- Top Bar -->
    <header class="h-12 w-full bg-0 border-30 border-b">
      <div class="w-full h-full p-2 flex justify-between items-center">
        <div class="h-full w-32 px-2 py-1 flex">
          <fa-icon
            icon="bars"
            class="mr-2 cursor-pointer"
            *ngIf="layout !== 'desktop'"
            (click)="toggleSidebar(sidebar)"
          ></fa-icon>
          <img
            [src]="logo"
            style="
              max-height: unset;
              max-width: unset;
              width: 92px;
              height: 23px;
            "
          />
        </div>
        <div class="flex">
          <div *ngIf="email === 'm.borisova@wheeliot.com'">
            <p class="rounded px-2 py-1 mr-2 text-sm bg-30">
              <fa-icon
                icon="address-card"
                class="opacity-75 mr-2"
                [fixedWidth]="true"
              ></fa-icon
              >saleswoman
            </p>
          </div>

          <div *ngIf="email !== 'm.borisova@wheeliot.com'">
            <p class="rounded px-2 py-1 mr-2 text-sm bg-30">
              <fa-icon
                icon="address-card"
                class="opacity-75 mr-2"
                [fixedWidth]="true"
              ></fa-icon
              >{{ role !== "truckFitter" ? role : "Allestitore" }}
            </p>
          </div>

          <select
            class="rounded px-2 py-1 mr-2 text-sm bg-30"
            [(ngModel)]="companyId"
            (change)="changeCompany($event.target.value)"
          >
            <option *ngFor="let c of companies" [value]="c.companyId">
              {{ c.name }}
            </option>
          </select>
          <a
            #notiButton
            class="relative rounded mr-2 px-3 py-1 bg-30 hover:bg-primary hover:text-primary-contrast tr-25 cursor-pointer"
            (click)="openNotibox(notiButton, notibox)"
          >
            <fa-icon icon="bell"></fa-icon>
            <span
              *ngIf="hasNotif"
              class="rounded-full bg-tertiary w-2 h-2 absolute top-0 right-0 m-1"
            ></span>
          </a>
          <a
            #menuButton
            class="rounded px-3 py-1 bg-30 hover:bg-primary hover:text-primary-contrast tr-25 cursor-pointer"
            (click)="openUserbox(menuButton, userbox)"
          >
            <fa-icon icon="ellipsis-v"></fa-icon>
          </a>
        </div>
      </div>
    </header>
    <!-- Main Content -->
    <div
      class="w-full h-full p-2"
      [@entryOutlet]="o.isActivated ? o.activatedRoute : ''"
    >
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
</div>

<ng-template #loading> loading... </ng-template>

<ng-template #sidebar>
  <nav
    class="fixed top-0 bottom-0 left-0 w-full md:w-56 p-2 h-screen bg-primary flex flex-col shadow z-50 overflow-y-auto"
  >
    <div class="my-4 w-full flex flex-col items-center justify-center">
      <img class="relative" width="186" height="124" [src]="wheeliot_logo" />
    </div>
    <ng-container *ngFor="let m of menus">
      <a
        [routerLink]="m.path"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="checkTermsAndConditions()"
        class="flex p-2 w-full rounded cursor-pointer nav-item tr-25 mb-1 leading-none items-center text-primary-contrast"
      >
        <fa-icon [icon]="m.icon" class="mr-2" [fixedWidth]="true"></fa-icon>
        <span>{{ m.label }}</span>
      </a>
    </ng-container>
    <span *ngIf="layout !== 'dekstop'" style="height: 30px"></span>
  </nav>
</ng-template>

<ng-template #userbox>
  <div
    class="bg-0 py-2 md:p-0 rounded flex flex-row w-full justify-between items-center"
  >
    <div class="truncate flex flex-col w-full">
      <p
        *ngIf="role !== 'customer'"
        class="truncate py-2 px-4 md:px-2 cursor-pointer"
        (click)="getPdf()"
      >
        <fa-icon
          icon="file-pdf"
          class="opacity-75 mr-2"
          [fixedWidth]="true"
        ></fa-icon>
        <span>{{ "menu.manual" | translate }}</span>
      </p>
      <p
        class="truncate py-2 px-4 md:px-2 cursor-pointer"
        (click)="toggleTheme()"
      >
        <fa-icon
          [icon]="toggle"
          class="opacity-75 mr-2"
          [fixedWidth]="true"
        ></fa-icon>
        <span>{{ "menu.dark-theme" | translate }}</span>
      </p>
      <p class="truncate py-2 px-4 md:px-2 cursor-pointer" (click)="signOut()">
        <fa-icon
          icon="sign-out-alt"
          class="opacity-75 mr-2"
          [fixedWidth]="true"
        ></fa-icon>
        <span>{{ "menu.sign-out" | translate }}</span>
      </p>
      <p class="truncate py-2 px-4 md:px-2" style="align-items: flex-end">
        v. 3.2.1
      </p>
    </div>
  </div>
</ng-template>

<ng-template #notibox>
  <app-notifications-latest></app-notifications-latest>
</ng-template>

<ng-template #termsAndConditionsModal>
  <app-terms-conditions-modal [user]="userDetails"></app-terms-conditions-modal>
</ng-template>

<ng-template #unresolvedModal>
  <div class="p-3 w-full mb-2">
    <div class="font-bold">Servizio sospeso.</div>
    <div class="mt-3">Contattare l'amministrazione.</div>
    <div class="p-1 w-full flex justify-end">
      <button type="submit" class="red" (click)="signOut()">
        <fa-icon icon="sign-out-alt"></fa-icon>
        <span class="ml-2">{{ "menu.sign-out" | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
