import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AmplifyAngularModule, AmplifyModules, AmplifyService } from 'aws-amplify-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/layout/main/main.component';
import { NotificationsLatestComponent } from './components/notifications-latest/notifications-latest.component';
import { LoaderComponent } from './components/overlays/loader/loader.component';
import { MenuComponent } from './components/overlays/menu/menu.component';
import { ModalComponent } from './components/overlays/modal/modal.component';
import { SidebarComponent } from './components/overlays/sidebar/sidebar.component';
import { ToastComponent } from './components/overlays/toast/toast.component';
import { Interceptor } from './middlewares';
import { ErrorComponent } from './pages/error/error.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginEDOComponent } from './pages/loginEDO/loginEDO.component';
import { SharedModule } from './shared/shared.module';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QrCodeModule } from 'ng-qrcode';
import { QrcodeVerificationComponent } from './components/qrcode-verification/qrcode-verification.component';
import { TermsConditionsModalComponent } from './components/terms-conditions-modal/terms-conditions-modal.component';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    LoginComponent,
    LoginEDOComponent,
    MainComponent,
    ModalComponent,
    SidebarComponent,
    ToastComponent,
    MenuComponent,
    LoaderComponent,
    NotificationsLatestComponent,
    QrcodeVerificationComponent,
    TermsConditionsModalComponent,
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    OverlayModule,
    AppRoutingModule,
    AmplifyAngularModule,
    QrCodeModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  entryComponents: [
    ModalComponent,
    SidebarComponent,
    ToastComponent,
    MenuComponent,
    LoaderComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT', deps: [TranslateService] },
		{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: AmplifyService, useFactory: () => AmplifyModules({ Auth, Storage }) },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
