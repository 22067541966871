import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { Notification } from 'src/app/utils/types';
import { OverlayService } from 'src/app/services/overlay/overlay.service';

@Component({
  selector: 'app-notifications-latest',
  templateUrl: './notifications-latest.component.html',
  styleUrls: ['./notifications-latest.component.css']
})
export class NotificationsLatestComponent implements OnInit {

  public rows: Array<Notification> = [];
  public isLoading = false;

  constructor(private api: ApiService, private overlay: OverlayService) { }

  ngOnInit() {
    this.search();
  }

  public search() {
    this.isLoading = true;
    this.api.getNotifications({ latestOnly: '1' }).subscribe(
      (r) => {
        this.rows = r.notifications;
        this.isLoading = false;
      },
      () => this.isLoading = false
    );
  };

  public markAsRead(insertedAt: number, id: string, index: number) {
    this.api.markNotificationAsRead(id, `${insertedAt}`).subscribe(
      (r) => {
        if (r.status === 204) {
          this.overlay.showToast({ color: 'green', icon: 'check', text: 'Notifica segnata come letta' });
          this.rows[index] = { ...this.rows[index], status: 'read' };
        }
      },
      () => {}
    );
  }
}
