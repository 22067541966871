import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnimationState, OverlayData, OverlayComponentRef } from 'src/app/utils/types';
import { AnimationEvent } from '@angular/animations';
import { fadeTop } from 'src/app/animations/fade';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
  animations: [fadeTop]
})
export class ToastComponent implements OnInit, OnDestroy {

  private intervalId: number;

  public animationState: AnimationState;
  public classList: string;

  constructor(readonly data: OverlayData, readonly ref: OverlayComponentRef) { }

  ngOnInit() {
    switch (this.data.color) {
      case 'green': this.classList = 'bg-green text-green-contrast'; break;
      case 'orange': this.classList = 'bg-tertiary text-tertiary-contrast'; break;
      case 'red': this.classList = 'bg-red text-red-contrast'; break;
      default: this.classList = 'bg-primary text-primary-contrast'; break;
    }
    this.animationState = 'open';
    if (!this.data.timeout) { this.data.timeout = 3000; }
    if (this.data.timeout !== 'infinite') {
      this.intervalId = window.setTimeout(() => this.animationState = 'close', this.data.timeout);
    }
  }

  ngOnDestroy() {
		clearTimeout(this.intervalId);
	}

  close() {
    this.animationState = 'close';
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    if (toState === 'close' && this.animationState === 'close') {
      this.ref.close();
    }
  }
}
