<div id="conditions" class="p-3 w-full mb-2 d-flex">
    <p class="font-bold mb-2">{{ 'terms.privacy-policy-title' | translate }}</p>
    <p>{{ 'terms.privacy-intro' | translate }}</p>

    <p class="font-bold mt-3">{{ 'terms.treatment-holder' | translate }}</p>
    <p>{{ 'terms.treatment-holder-text' | translate }}</p>

    <p class="font-bold mt-3">{{ 'terms.personal-data' | translate }}</p>
    <p>{{ 'terms.personal-data-text' | translate}}</p>

    <p class="font-bold mt-3">{{ 'terms.legal-finality' | translate }}</p>
    <p>{{ 'terms.legal-finality-text-1' | translate}}:</p>

    <table class="mt-1">
        <tr>
            <th>{{ 'terms.finality' | translate }}</th>
            <th>{{ 'terms.processed-data' | translate }}</th>
        </tr>
        <tr>
            <td class="p-3">
                <ol style="list-style-type: decimal;">
                    <li>{{ 'terms.finality-1' | translate }};</li>
                    <li>{{ 'terms.finality-2' | translate }};</li>
                    <li>{{ 'terms.finality-3' | translate }};</li>
                    <li>{{ 'terms.finality-4' | translate }};</li>
                    <li>{{ 'terms.finality-5' | translate }}.</li> 
                </ol>
            </td>
            <td class="p-3">
                <ol style="list-style-type: lower-alpha;">
                    <li>{{ 'terms.processed-data-1' | translate }};</li>
                    <li>{{ 'terms.processed-data-2' | translate }};</li> 
                    <li>{{ 'terms.processed-data-3' | translate }}.</li> 
                </ol>
            </td>
        </tr>
    </table> 

    <p class="font-bold mt-3">{{ 'terms.legal-basis' | translate }}</p>
    <ol style="list-style-type: lower-roman;">
        <li>{{ 'terms.legal-basis-1' | translate }};</li>
        <li>{{ 'terms.legal-basis-2' | translate }};</li>
        <li>{{ 'terms.legal-basis-3' | translate }};</li>
        <li>{{ 'terms.legal-basis-4' | translate }};</li>
        <li>{{ 'terms.legal-basis-5' | translate }}.</li>
    </ol>

    <p>{{ 'terms.legal-finality-text-2' | translate }}</p>

    <p class="font-bold mt-3">{{ 'terms.modality' | translate }}</p>
    <p>{{ 'terms.modality-text' | translate }}</p>

    <p class="font-bold mt-3">{{ 'terms.data-retention' | translate }}</p>
    <p>{{ 'terms.data-retention-text' | translate }}</p>

    <p class="font-bold mt-3">{{ 'terms.data-transfer' | translate }}</p>
    <p>{{ 'terms.data-transfer-text' | translate }}</p>

    <p class="font-bold mt-3">{{ 'terms.diffusion-scope' | translate }}</p>
    <p>{{ 'terms.diffusion-scope-text' | translate }}</p>
    
    <p class="font-bold mt-3">{{ 'terms.rights' | translate }}</p>
    <p>{{ 'terms.rights-text-1' | translate }}:</p>
    <ol style="list-style-type: lower-alpha;">
        <li>{{ 'terms.rights-1' | translate }};</li>
        <li>{{ 'terms.rights-2' | translate }};</li>
        <li>{{ 'terms.rights-3' | translate }};</li>
        <li>{{ 'terms.rights-4' | translate }};</li>
        <li>{{ 'terms.rights-5' | translate }};</li>
        <li>{{ 'terms.rights-6' | translate }};</li>
        <li>{{ 'terms.rights-7' | translate }};</li>
        <li>{{ 'terms.rights-8' | translate }};</li>
        <li>{{ 'terms.rights-9' | translate }};</li>
        <li>{{ 'terms.rights-10' | translate }}.</li>
    </ol>

    <p>{{ 'terms.rights-text-2' | translate }}: <span class="font-bold">dpo@mgh-systems.com</span></p>

    <p class="font-bold font-italic mt-3">{{ 'terms.definitions' | translate }}</p>
    <ul class="font-italic">
        <li class="pb-2">
            <u>{{ 'terms.personal-data-term' | translate }}: </u> {{ 'terms.personal-data-def' | translate }}
        </li>
        <li class="pb-2">
            <u>{{ 'terms.particular-data-term' | translate }}: </u> {{ 'terms.personal-data-def' | translate }}
        </li>
        <li class="pb-2">
            <u>{{ 'terms.treatment-term' | translate }}: </u> {{ 'terms.treatment-def' | translate }}
        </li>
        <li class="pb-2">
            <u>{{ 'terms.subject-term' | translate }}: </u> {{ 'terms.subject-def' | translate }}
        </li>
        <li class="pb-2">
            <u>{{ 'terms.treatment-holder-term' | translate }}: </u> {{ 'terms.treatment-holder-def' | translate }}
        </li>
        <li class="pb-2">
            <u>{{ 'terms.data-controller-term' | translate }}: </u> {{ 'terms.data-controller-def' | translate }}
        </li>
        <li class="pb-2">
            <u>{{ 'terms.authorised-personnel-term' | translate }}: </u> {{ 'terms.authorised-personnel-def' | translate }}
        </li>
        <li class="pb-2">
            <u>{{ 'terms.dpo-term' | translate }}: </u> {{ 'terms.dpo-def' | translate }}
        </li>
    </ul>

    <p id="page-foot">
        {{ 'terms.review' | translate }} <br>
        <span class="font-bold">MGH SYSTEMS ITALIA SRL</span><br>
        Via Lecco, 170 <br>
        20900, Monza, MB – Italy <br>
        CF e Partita IVA = 10147980964 <br>
        www.mgh-systems.com <br>
    </p>
</div>

<form [formGroup]="form" (ngSubmit)="onSave()">
    <div class="p-1 flex flex-wrap items-end">
        <div class="p-1 w-full md:w-1/2 flex flex-col">
            <label class="opacity-75 text-sm font-bold pb-1">{{ 'users.full-name' | translate }} *</label>
            <input type="text" formControlName="fullName"
            [ngStyle]="{'border': (saved && form.controls.fullName.invalid) ? '2px solid #F28F1C' : ''}" />
        </div>
        <div class="p-1 w-full md:w-1/2 flex flex-col">
            <label class="opacity-75 text-sm font-bold pb-1">{{ 'terms.cf' | translate }} *</label>
            <input type="text" formControlName="cf"
            [ngStyle]="{'border': (saved && form.controls.cf.invalid) ? '2px solid #F28F1C' : ''}" />
        </div>
        <div class="p-1 w-full md:w-1/2 flex flex-col">
            <label class="opacity-75 text-sm font-bold pb-1">{{ 'terms.place' | translate }} *</label>
            <input type="text" formControlName="place"
            [ngStyle]="{'border': (saved && form.controls.place.invalid) ? '2px solid #F28F1C' : ''}" />
        </div>
        <div class="p-1 w-full md:w-1/2 flex flex-col">
            <label class="opacity-75 text-sm font-bold pb-1">{{ 'dashboard.date' | translate }} *</label>
            <input type="date" formControlName="date"
            [ngStyle]="{'border': (saved && form.controls.date.invalid) ? '2px solid #F28F1C' : ''}" />
        </div>
        <div class="p-1 w-full mb-2 mt-3">
            <input type="checkbox" name="terms" id="terms" formControlName="terms" />
            <label class="ml-1" for="terms" 
            [ngStyle]="{'color': (saved && form.controls.terms.invalid) ? '#F28F1C' : '', 'font': (saved && form.controls.terms.invalid) ? 'bold' : ''}" >
            {{ 'terms.general-terms' | translate }} <span class="font-bold">{{ 'terms.mandatory' | translate }}</span>
            </label>
        </div>

        <div class="p-1 w-full mb-2">
            <p class="ml-1">
                {{ 'terms.marketing-terms' | translate }}
            </p>
            <div>
                <input type="radio" name="marketing" id="allow1" formControlName="marketing" [value]="true"/>
                <label class="opacity-75 text-sm font-bold ml-2" for="allow1"
                [ngStyle]="{'color': (saved && form.controls.marketing.invalid) ? '#F28F1C' : '', 'font': (saved && form.controls.marketing.invalid) ? 'bold' : ''}" >
                    {{ 'terms.allow' | translate }} 
                </label>
            </div>
            <div>
                <input type="radio" name="marketing" id="deny1" formControlName="marketing" [value]="false"/>
                <label class="opacity-75 text-sm font-bold ml-2" for="deny1"
                [ngStyle]="{'color': (saved && form.controls.marketing.invalid) ? '#F28F1C' : '', 'font': (saved && form.controls.marketing.invalid) ? 'bold' : ''}" >
                    {{ 'terms.deny' | translate }}  
                </label>
            </div>
        </div>

        <div class="p-1 w-full mb-1">
            <p class="ml-1">
                {{ 'terms.extra-eu-terms' | translate }}
            </p>
            <div>
                <input type="radio" name="extraEu" id="allow2" formControlName="extraEu" [value]="true"/>
                <label class="opacity-75 text-sm font-bold ml-2" for="allow2"
                [ngStyle]="{'color': (saved && form.controls.extraEu.invalid) ? '#F28F1C' : '', 'font': (saved && form.controls.extraEu.invalid) ? 'bold' : ''}" >
                    {{ 'terms.allow' | translate }} 
                </label>
            </div>
            <div>
                <input type="radio" name="extraEu" id="deny2" formControlName="extraEu" [value]="false" />
                <label class="opacity-75 text-sm font-bold ml-2" for="deny2"
                [ngStyle]="{'color': (saved && form.controls.extraEu.invalid) ? '#F28F1C' : '', 'font': (saved && form.controls.extraEu.invalid) ? 'bold' : ''}" >
                    {{ 'terms.deny' | translate }}  
                </label>
            </div>
        </div>
        
        <div class="p-1 w-full flex justify-end">
            <button type="submit" class="primary">
                <fa-icon icon="save" class="mr-1"></fa-icon>{{ 'companies.save' | translate }}
            </button>
        </div>
    </div>
</form>