import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PATHS } from 'src/app/app.routes';
import { ApiService } from 'src/app/services/api/api.service';
import { StoreService } from 'src/app/services/store/store.service';
import { OverlayComponentRef } from 'src/app/utils/types';
import { OverlayService } from 'src/app/services/overlay/overlay.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('qrCodeVerificationTemplate', { static: true })
  private qrCodeVerificationTemplate: TemplateRef<any>;

  public loginForm: FormGroup;
  public forgotForm: FormGroup;
  public pwForm: FormGroup;
  public resetForm: FormGroup;
  public modal: OverlayComponentRef;
  public code: string;
  public totpCode: string;  

  private tmpUser: any;

  public isLoading = false;
  public isLoadingReset = false;
  public resetState: 'hidden' | 'confirmPw' | 'sendEmail' | 'updatePw';

  constructor(
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private overlay: OverlayService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {

    this.auth.getSession().then(s => {
      if (s) {
        this.auth.user.then((u: any) => {
          if (u.attributes['custom:mfa'] && u.attributes['custom:mfa'] === '1' && u.preferredMFA === 'NOMFA') { }
          else {
            this.router.navigate([PATHS.map]);
          }
        })
      }
    });

    this.resetState = 'hidden';
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(4), Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(4), Validators.email]]
    });
    this.pwForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$"))]],
    });
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(4), Validators.email]],
      code: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.pattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$"))]],
    });
  }

  openForgotForm() {
    this.forgotForm.patchValue({ email: this.loginForm.controls.email.value });
    this.resetState = 'sendEmail';
  }

  openResetForm() {
    this.resetForm.patchValue({ email: this.loginForm.controls.email.value });
    this.resetState = 'updatePw';
  }

  openPasswordForm() {
    this.resetState = 'confirmPw';
  }

  onLoginSubmit() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.auth.signIn(this.loginForm.value).then((u) => {
        if (u && u.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.tmpUser = u;
          this.resetState = 'confirmPw';
        } else if (u && u.preferredMFA === 'NOMFA') {
          this.tmpUser = u;
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(u.signInUserSession.idToken.jwtToken);
          console.log('decodedToken', decodedToken);
          if (decodedToken['custom:mfa'] && decodedToken['custom:mfa'] === '1') {
            this.auth.setupTOTP(u).then((code) => {
              if (code) {
                this.code = code;
                this.qrCodeVerification(this.qrCodeVerificationTemplate);
              }
            });
          } else {
            this.api.lastLogin(u.attributes.sub, u.signInUserSession.accessToken.jwtToken).subscribe();
            this.router.navigate([PATHS.map]);
          }
        } else if (u && u.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.tmpUser = u;
          this.code = undefined;
          this.qrCodeVerification(this.qrCodeVerificationTemplate);
        }
        this.isLoading = false;
      });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  public closeVerification(code: string) {
    this.auth.signIn(this.loginForm.value).then((u) => {
      this.auth.confirmSignIn(u, code).then(() => {
        this.router.navigate([PATHS.map]);
      });
    });
  }

  public qrCodeVerification(template: TemplateRef<any>) {
    this.modal = this.overlay.showModal({ template, text: this.translateService.instant('login.verify-identity'), icon: 'qrcode', width: '706px' });
  }

  async onForgotSubmit() {
    if (this.forgotForm.valid) {
      this.isLoadingReset = true;
      try {
        await this.auth.forgotPassword(this.forgotForm.value);
        this.isLoadingReset = false;
        this.openResetForm();
      } catch (err) {
        this.isLoadingReset = false;
      }
    } else {
      this.isLoadingReset = false;
      this.forgotForm.markAllAsTouched();
    }
  }

  async onPwSubmit() {
    if (this.pwForm.valid) {
      this.isLoadingReset = true;
      try {
        const newPw = this.pwForm.controls.password.value;
        const response = await this.auth.completePassword(this.tmpUser, newPw);
        if (response) {
          this.isLoadingReset = false;
          this.resetState = 'hidden';
          // this.loginForm.controls.password.setValue(newPw);
          // this.onLoginSubmit();
          // this.router.navigate([PATHS.map]);
          this.loginForm.controls.password.setValue(newPw);
          this.onLoginSubmit();
        }
      } catch (err) {
        this.isLoadingReset = false;
      }
    } else {
      this.pwForm.markAllAsTouched();
    }
  }

  async onResetSubmit() {
    if (this.resetForm.valid) {
      this.isLoadingReset = true;
      try {
        await this.auth.resetPassword(this.resetForm.value);
        this.isLoadingReset = false;
        this.resetState = 'hidden';
        this.loginForm.setValue({
          email: this.resetForm.value.email,
          password: this.resetForm.value.password,
        });
        this.onLoginSubmit();
      } catch (err) {
      }
    } else {
      this.resetForm.markAllAsTouched();
    }
  }

  isEdo() {
    return window.location.href.includes('edo.wheeliot');
  }
}
