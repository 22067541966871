import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Storage } from 'aws-amplify';

type FileUpType = 'image/png' | 'application/pdf';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private amplify: AmplifyService) {
    Storage.configure({
      customPrefix: {
        public: 'public/',
        protected: '/',
        private: ''
      }
    });
  }

  public async uploadImage(thumbnail: { name?: string; base64: string; type: FileUpType }): Promise<{ key: string; name: string; url: string; }> {
    const blob = this.dataURItoBlob(thumbnail.base64, thumbnail.type);
    const format = thumbnail.type.split('/')[1];
    const name = `${thumbnail.name && thumbnail.name.length > 0 ? thumbnail.name : this.makeId()}.${format}`;
    try {
      const d = await this.amplify.storage().put(name, blob, { level: 'public', contentType: `${thumbnail.type}` });
      const url = await this.amplify.storage().get(d.key);
      return { key: d.key, name, url };
    } catch (error) {
      return error;
    }
  }

  public async uploadFile(name: string, file: File, level: string = 'public') {
    if (level === 'public') {
      return this.amplify.storage().put(name, file, { level: 'public', contentType: file.type });
    } else {
      return await Storage.put(name, file, {
        contentType: file.type,
        customPrefix: {
          public: ''
        }
      });
    }
  }

  public async deleteImage(filename: string, level: string = 'public') {
    try {
      if (level === 'public') {
        return await this.amplify.storage().remove(filename);
      } else {
        return await this.amplify.storage().remove(filename, {
          customPrefix: {
            public: ''
          }
        });
      }
    } catch (error) {
      return error;
    }
  }

  public async get(filename: string, options: any = { level: 'public' }) {
    try {
      const name = filename.substr(7);
      return await Storage.get(filename, options);
    } catch (error) {
      return error;
    }
  }

  public makeId() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 25; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  private dataURItoBlob(dataURI: string, type: FileUpType) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type });
  }
}
