import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totTime'
})
export class TotTimePipe implements PipeTransform {

  transform(value: string | number): string {
    if (+value <= 0) {
      return '0';
    }
    let d: number;
    let h: number;
    let m: number;
    let s: number;
    s = Math.floor(+value / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    var pad = (n: number) => n < 10 ? `0${n}` : n;
    return (d < 1 ? '' : (d + 'gg ')) + (pad(h) + ':' + pad(m) + ':' + pad(s));
  }

  transformHours(value: string | number): string {
    if (+value <= 0) {
      return '0';
    }
    let d: number;
    let h: number;
    let m: number;
    let s: number;
    s = Math.floor(+value / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    var pad = (n: number) => n < 10 ? `0${n}` : n;
    return (pad(h) + ':' + pad(m) + ':' + pad(s));
  }
}