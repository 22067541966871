<div class="flex flex-col items-center justify-center h-screen bg-gray-100">
	<h1 class="text-6xl text-gray-600 font-bold text-center">Error <i class="remixicon-emotion-unhappy-fill text-grey-200"></i></h1>
	<div class="flex text-gray-500">
		<button (click)="goBack()" class="tr-33 flex-1 rounded bg-gray-200 p-2 mr-4 hover:shadow hover:text-gray-600 hover:bg-gray-300">
			Go Back
		</button>
		<button class="tr-33 flex-1 rounded bg-gray-200 p-2 hover:shadow hover:text-gray-600 hover:bg-gray-300">
			<a href="/">Homepage</a>
		</button>
	</div>
</div>