import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { OverlayService } from 'src/app/services/overlay/overlay.service';
import { PATHS } from 'src/app/app.routes';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-qrcode-verification',
  templateUrl: './qrcode-verification.component.html',
  styleUrls: ['./qrcode-verification.component.css']
})
export class QrcodeVerificationComponent implements OnInit {

  @Input() code: string;
  @Input() user: any;
  @Output() close = new EventEmitter();

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private overlay: OverlayService,
    private api: ApiService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      verificationCode: ['', Validators.required]
    })
  }

  public chooseAction() {
    if (this.code) {
      this.verifyTotpToken();
    } else {
      this.confirmSignIn();
    }
  }

  public verifyTotpToken() {
    if (this.code && this.form.valid) {
      this.auth.verifyTotpToken(this.user, this.form.controls.verificationCode.value).then((res: any) => {
        if (res.Status === 'SUCCESS') {
          this.close.emit(this.form.controls.verificationCode.value);
          this.overlay.closeModal();
          this.overlay.showToast({ icon: 'check-circle', text: this.translateService.instant('login.account-verification-success'), color: 'green' });
        } else {
          this.overlay.showToast({ icon: 'frown', color: 'orange', text: this.translateService.instant('login.incorrect-verification-code') });
        }
      })
        .catch(err => {
          console.log('error', err);
        });
    } else {
      this.overlay.showToast({ icon: 'frown', color: 'orange', text: this.translateService.instant('login.insert-verification-code') });
    }
  }

  public confirmSignIn() {
    if (this.form.valid) {
      this.auth.confirmSignIn(this.user, this.form.controls.verificationCode.value).then(() => {
        this.overlay.closeModal();
        this.api.lastLogin(this.user.username, this.user.signInUserSession.accessToken.jwtToken).subscribe();
        this.router.navigate([PATHS.map]);
      })
      .catch((err) => {
        console.log('error', err);
      })
    } else {
      this.overlay.showToast({ icon: 'frown', color: 'orange', text: this.translateService.instant('login.insert-verification-code') });
    }
  }

}
