<div class="bg-primary w-screen h-2 absolute" *ngIf="!isEdo()"></div>
<section class="w-full max-w-xs mx-auto flex flex-col items-center" *ngIf="!isEdo()">
  <div class="mt-12 w-full flex flex-col items-center justify-center">
    <img class="relative" width="150" src="/assets/img/wheeliot_logo.png"/>
    <img class="relative" width="150" src="/assets/img/wheeliot.png"/>
  </div>
  <div class="p-4 w-full">

      <!-- LOGIN FORM -->
    <form class="w-full" [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" method="post">
      <div class="relative">
        <div [class.hidden]="resetState === 'hidden'" class="absolute w-full h-full bg-10 opacity-75 inset-x-0 inset-y-0"></div>
        <div class="h-10 w-full py-1 flex flex-col items-end font-bold text-xs leading-none justify-end">
          <p class="px-2 py-1" *ngIf="loginForm.controls.email.invalid && (loginForm.controls.email.dirty || loginForm.controls.email.touched)">
            <span *ngIf="loginForm.controls.email.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </p>
          <p class="px-2 py-1" *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
            <span *ngIf="loginForm.controls.password.hasError"> {{ 'login.min-pw-length' | translate }}! </span>
          </p>
        </div>
        <div class="py-2">
          <input formControlName="email" type="email" placeholder="email@example.com" required="required" class="w-full">
        </div>
        <div class="py-2">
          <input formControlName="password" type="password" placeholder="******" required="required" class="w-full">
        </div>
        <div class="py-2">
          <button type="submit" id="login" class="primary w-full">
            <span *ngIf="isLoading">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.connection' | translate }}...
            </span>
            <span *ngIf="!isLoading">
              <fa-icon icon="sign-in-alt" fixed-with="true" class="mr-1"></fa-icon>{{ 'login.login' | translate }}
            </span>
          </button>
        </div>
      </div>
    </form>

    <div class="w-full">
      <div class="border-t border-30 mt-2 py-4 text-xs flex justify-between">
        <div class="cursor-pointer hover:underline" (click)="openForgotForm()">
          <fa-icon icon="question-circle" class="mr-2"></fa-icon>{{ 'login.forgot-password' | translate }}
        </div>
        <div *ngIf="resetState === 'sendEmail'" class="cursor-pointer hover:underline" (click)="openResetForm()">
          <fa-icon icon="question-circle" class="mr-2"></fa-icon>{{ 'login.already-have-code' | translate }}
        </div>
      </div>

      <!-- CONFIRM PW NEW USER -->
      <form class="py-1" [formGroup]="pwForm" (ngSubmit)="onPwSubmit()" method="post" [class.hidden]="resetState !== 'confirmPw'">
        <p class="leading-none py-1 text-sm opacity-75">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.new-password' | translate }}
          <span class="float-right" *ngIf="pwForm.controls.password.invalid && (pwForm.controls.password.dirty || pwForm.controls.password.touched)">
            <span *ngIf="pwForm.controls.password.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="password" formControlName="password" class="w-full" placeholder="********"/>
        </div>
        <div class="py-1 flex">
          <button type="button" class="flex-shrink-0 mr-2" (click)="resetState = 'hidden'">
            <fa-icon icon="times" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.close' | translate }}
          </button>
          <button class="green flex-grow" [disabled]="!pwForm.valid ? 'disabled' : null">
            <span *ngIf="isLoadingReset">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.send-request' | translate }}...
            </span>
            <span *ngIf="!isLoadingReset">
              <fa-icon icon="key" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.confirm-new-pw' | translate }}
            </span>
          </button>
        </div>
      </form>

      <!-- SEND CODE TO EMAIL -->
      <form class="py-1" [formGroup]="forgotForm" (ngSubmit)="onForgotSubmit()" method="post" [class.hidden]="resetState !== 'sendEmail'">
        <p class="leading-none py-1 text-sm opacity-75">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.your-email' | translate }}
          <span class="float-right" *ngIf="forgotForm.controls.email.invalid && (forgotForm.controls.email.dirty || forgotForm.controls.email.touched)">
            <span *ngIf="forgotForm.controls.email.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="email" formControlName="email" class="w-full" placeholder="email@example.com"/>
        </div>
        <div class="py-1 flex">
          <button type="button" class="flex-shrink-0 mr-2" (click)="resetState = 'hidden'">
            <fa-icon icon="times" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.close' | translate }}
          </button>
          <button class="green flex-grow" [disabled]="!forgotForm.valid ? 'disabled' : null">
            <span *ngIf="isLoadingReset">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.send-request' | translate }}...
            </span>
            <span *ngIf="!isLoadingReset">
              <fa-icon icon="envelope" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.send-request' | translate }}...
            </span>
          </button>
        </div>
      </form>

      <!-- RESET PW WITH CODE -->
      <form class="py-1" [formGroup]="resetForm" (ngSubmit)="onResetSubmit()" method="post" [class.hidden]="resetState !== 'updatePw'">
        <p class="leading-none py-1 text-sm opacity-75">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.your-email' | translate }}
          <span class="float-right" *ngIf="resetForm.controls.email.invalid && (resetForm.controls.email.dirty || resetForm.controls.email.touched)">
            <span *ngIf="resetForm.controls.email.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="email" formControlName="email" class="w-full" placeholder="email@example.com"/>
        </div>
        <p class="leading-none py-1 text-sm opacity-75 mt-2">
          <fa-icon icon="key" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.confirmation-code' | translate }}
          <span class="float-right" *ngIf="resetForm.controls.code.invalid && (resetForm.controls.code.dirty || resetForm.controls.code.touched)">
            <span *ngIf="resetForm.controls.code.hasError"> {{ 'login.insert-valid-code' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="text" formControlName="code" class="w-full" placeholder="123456"/>
        </div>
        <p class="leading-none py-1 text-sm opacity-75 mt-2">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.new-password-details' | translate }}
        </p>
        <div class="py-1">
          <input type="password" formControlName="password" class="w-full" placeholder="******"/>
        </div>
        <div class="py-1 flex">
          <button type="button" class="flex-shrink-0 mr-2" (click)="resetState = 'hidden'">
            <fa-icon icon="times" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.close' | translate }}
          </button>
          <button class="green flex-grow" [disabled]="!resetForm.valid ? 'disabled' : null">
            <span *ngIf="isLoadingReset">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.update' | translate }}...
            </span>
            <span *ngIf="!isLoadingReset">
              <fa-icon icon="sync-alt" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.update-pw' | translate }}
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</section>

<div class="bg-primaryEDO w-screen h-2 absolute" *ngIf="isEdo()"></div>
<section class="w-full max-w-xs mx-auto flex flex-col items-center" *ngIf="isEdo()">
  <div class="mt-12 w-full flex flex-col items-center justify-center">
    <img class="relative" width="150" src="/assets/img/EDO_LOGO_registrato.png"/>
    <!-- <img class="relative" width="150" src="/assets/img/wheeliot.png"/> -->
  </div>
  <div class="p-4 w-full">

      <!-- LOGIN FORM -->
    <form class="w-full" [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" method="post">
      <div class="relative">
        <div [class.hidden]="resetState === 'hidden'" class="absolute w-full h-full bg-10 opacity-75 inset-x-0 inset-y-0"></div>
        <div class="h-10 w-full py-1 flex flex-col items-end font-bold text-xs leading-none justify-end">
          <p class="px-2 py-1" *ngIf="loginForm.controls.email.invalid && (loginForm.controls.email.dirty || loginForm.controls.email.touched)">
            <span *ngIf="loginForm.controls.email.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </p>
          <p class="px-2 py-1" *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
            <span *ngIf="loginForm.controls.password.hasError"> {{ 'login.min-pw-length' | translate }}! </span>
          </p>
        </div>
        <div class="py-2">
          <input formControlName="email" type="email" placeholder="email@example.com" required="required" class="w-full">
        </div>
        <div class="py-2">
          <input formControlName="password" type="password" placeholder="******" required="required" class="w-full">
        </div>
        <div class="py-2">
          <button type="submit" id="login" class="primaryEDO w-full">
            <span *ngIf="isLoading">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.connection' | translate }}...
            </span>
            <span *ngIf="!isLoading">
              <fa-icon icon="sign-in-alt" fixed-with="true" class="mr-1"></fa-icon>{{ 'login.login' | translate }}
            </span>
          </button>
        </div>
      </div>
    </form>

    <div class="w-full">
      <div class="border-t border-30 mt-2 py-4 text-xs flex justify-between">
        <div class="cursor-pointer hover:underline" (click)="openForgotForm()">
          <fa-icon icon="question-circle" class="mr-2"></fa-icon>{{ 'login.forgot-password' | translate }}
        </div>
        <div *ngIf="resetState === 'sendEmail'" class="cursor-pointer hover:underline" (click)="openResetForm()">
          <fa-icon icon="question-circle" class="mr-2"></fa-icon>{{ 'login.already-have-code' | translate }}
        </div>
      </div>

      <!-- CONFIRM PW NEW USER -->
      <form class="py-1" [formGroup]="pwForm" (ngSubmit)="onPwSubmit()" method="post" [class.hidden]="resetState !== 'confirmPw'">
        <p class="leading-none py-1 text-sm opacity-75">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.new-password' | translate }}
          <span class="float-right" *ngIf="pwForm.controls.password.invalid && (pwForm.controls.password.dirty || pwForm.controls.password.touched)">
            <span *ngIf="pwForm.controls.password.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="password" formControlName="password" class="w-full" placeholder="********"/>
        </div>
        <div class="py-1 flex">
          <button type="button" class="flex-shrink-0 mr-2" (click)="resetState = 'hidden'">
            <fa-icon icon="times" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.close' | translate }}
          </button>
          <button class="green flex-grow" [disabled]="!pwForm.valid ? 'disabled' : null">
            <span *ngIf="isLoadingReset">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.send-request' | translate }}...
            </span>
            <span *ngIf="!isLoadingReset">
              <fa-icon icon="key" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.confirm-new-pw' | translate }}
            </span>
          </button>
        </div>
      </form>

      <!-- SEND CODE TO EMAIL -->
      <form class="py-1" [formGroup]="forgotForm" (ngSubmit)="onForgotSubmit()" method="post" [class.hidden]="resetState !== 'sendEmail'">
        <p class="leading-none py-1 text-sm opacity-75">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.your-email' | translate }}
          <span class="float-right" *ngIf="forgotForm.controls.email.invalid && (forgotForm.controls.email.dirty || forgotForm.controls.email.touched)">
            <span *ngIf="forgotForm.controls.email.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="email" formControlName="email" class="w-full" placeholder="email@example.com"/>
        </div>
        <div class="py-1 flex">
          <button type="button" class="flex-shrink-0 mr-2" (click)="resetState = 'hidden'">
            <fa-icon icon="times" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.close' | translate }}
          </button>
          <button class="green flex-grow" [disabled]="!forgotForm.valid ? 'disabled' : null">
            <span *ngIf="isLoadingReset">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.send-request' | translate }}...
            </span>
            <span *ngIf="!isLoadingReset">
              <fa-icon icon="envelope" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.send-request' | translate }}...
            </span>
          </button>
        </div>
      </form>

      <!-- RESET PW WITH CODE -->
      <form class="py-1" [formGroup]="resetForm" (ngSubmit)="onResetSubmit()" method="post" [class.hidden]="resetState !== 'updatePw'">
        <p class="leading-none py-1 text-sm opacity-75">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.your-email' | translate }}
          <span class="float-right" *ngIf="resetForm.controls.email.invalid && (resetForm.controls.email.dirty || resetForm.controls.email.touched)">
            <span *ngIf="resetForm.controls.email.hasError"> {{ 'login.insert-valid-email' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="email" formControlName="email" class="w-full" placeholder="email@example.com"/>
        </div>
        <p class="leading-none py-1 text-sm opacity-75 mt-2">
          <fa-icon icon="key" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.confirmation-code' | translate }}
          <span class="float-right" *ngIf="resetForm.controls.code.invalid && (resetForm.controls.code.dirty || resetForm.controls.code.touched)">
            <span *ngIf="resetForm.controls.code.hasError"> {{ 'login.insert-valid-code' | translate }}! </span>
          </span>
        </p>
        <div class="py-1">
          <input type="text" formControlName="code" class="w-full" placeholder="123456"/>
        </div>
        <p class="leading-none py-1 text-sm opacity-75 mt-2">
          <fa-icon icon="at" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.new-password-details' | translate }}
        </p>
        <div class="py-1">
          <input type="password" formControlName="password" class="w-full" placeholder="******"/>
        </div>
        <div class="py-1 flex">
          <button type="button" class="flex-shrink-0 mr-2" (click)="resetState = 'hidden'">
            <fa-icon icon="times" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.close' | translate }}
          </button>
          <button class="green flex-grow" [disabled]="!resetForm.valid ? 'disabled' : null">
            <span *ngIf="isLoadingReset">
              <fa-icon icon="spinner" [spin]="true" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.update' | translate }}...
            </span>
            <span *ngIf="!isLoadingReset">
              <fa-icon icon="sync-alt" fixed-with="true" class="mr-2"></fa-icon>{{ 'login.update-pw' | translate }}
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</section>

<ng-template #qrCodeVerificationTemplate>
  <app-qrcode-verification [code]="code" [user]="tmpUser" (close)="closeVerification($event)"></app-qrcode-verification>
</ng-template>
