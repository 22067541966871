import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AppAnomalyCounter,
  AppAnomalyResponse,
  Audits,
  AuditsExport,
  AuditsLastKey,
  AuditsParams,
  Commands,
  Company,
  CustomerArea,
  CustomerDetails,
  CustomerMetadata,
  Diagnostics,
  ExportIotData,
  JourneyDetails,
  KpiFilters,
  Logs,
  LogsExport,
  LogsLastKey,
  LogsParams,
  MapExport,
  Notification,
  Operator,
  OperatorData,
  OptimalPath,
  Path,
  PathExport,
  PathExportKML,
  PathList,
  PathParams,
  PathsExport,
  PathsListParams,
  Poi,
  PoiDetails,
  PoiParams,
  PoiRoute,
  PoiRouteParams,
  RemoteDiagnostics,
  RemoteSession,
  ServiceOrderAreas,
  ServiceOrderStats,
  SmartBoxList,
  Telemetry,
  UpdateResult,
  User,
  Vehicle,
  VehicleConsumption,
  VehicleDailyStats,
  VehicleData,
  VehicleInfo,
  VehicleInfoUpdate,
  VehicleLastKey,
  VehicleStats,
  VehicleVideo,
  VehicleVideoParams,
  VehiclesFilters,
  VehiclesInfoParams,
  VehiclesParams,
  WorkShifts,
  WorkShiftsKey,
  WorkShiftsParams,
  WorkShiftsStats,
} from "src/app/utils/types";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiUri: string;

  constructor(private http: HttpClient) {
    this.apiUri = environment.api.uri + environment.api.path;
  }

  // ************* //
  // NOTIFICATIONS //
  // ************* //

  public getNotifications(
    params: {
      insertedAt?: string;
      status?: "read" | "unread";
      plateId?: string;
      latestOnly?: "1" | "0";
      lastInsertedAt?: string;
    } = null
  ) {
    return this.http.get<{
      notifications: Notification[];
      lastKey: { id: string; insertedAt: number };
    }>(`${this.apiUri}/notifications`, { params });
  }
  public getNotificationsForPlateId(params: { plateId: string }) {
    return this.http.get<Notification[]>(`${this.apiUri}/notifications`, {
      params,
    });
  }
  public markNotificationAsRead(id: string, insertedAt: string) {
    return this.http.put<any>(
      `${this.apiUri}/notifications/${id}/status`,
      null,
      { params: { insertedAt }, observe: "response" }
    );
  }
  public readAllNotifications() {
    return this.http.put<any>(`${this.apiUri}/notifications/readAll`, null, {
      observe: "response",
    });
  }

  // ********* //
  // OPERATORS //
  // ********* //

  getOperators(params: {
    name?: string;
    operatorId?: string;
    lastKey?: string;
    loadAll?: string;
  }) {
    return this.http.get<
      { operators: Array<Operator>; lastKey: { Id: string } } | Array<Operator>
    >(`${this.apiUri}/operators`, { params });
  }
  createOperator(body: Operator) {
    return this.http.post<UpdateResult>(`${this.apiUri}/operators`, body, {
      observe: "response",
    });
  }
  updateOperator(operatorId: number | string, body: OperatorData) {
    return this.http.put<UpdateResult>(
      `${this.apiUri}/operators/${operatorId}`,
      body,
      { observe: "response" }
    );
  }
  deleteOperator(operatorId: number | string) {
    return this.http.delete<any>(`${this.apiUri}/operators/${operatorId}`, {
      observe: "response",
    });
  }
  importOperator(key: string) {
    return this.http.post<any>(
      `${this.apiUri}/operators/import`,
      { key },
      { observe: "response" }
    );
  }
  exportOperators() {
    return this.http.get(`${this.apiUri}/operators/export`);
  }

  // ****** //
  // SHIFTS //
  // ****** //

  getShiftStats(params: {
    operatorId: string;
    startDate: string;
    endDate: string;
  }) {
    return this.http.get<{ statistics: WorkShiftsStats[] }>(
      `${this.apiUri}/shifts/statistics`,
      { params }
    );
  }
  getShifts(params: WorkShiftsParams) {
    return this.http.get<{ shifts: WorkShifts[]; lastKey: WorkShiftsKey }>(
      `${this.apiUri}/shifts`,
      { params }
    );
  }

  // ******** //
  // VEHICLES //
  // ******** //

  public getVehicles(params: VehiclesParams) {
    return this.http.get<Vehicle[]>(`${this.apiUri}/vehicles`, { params });
  }
  public createVehicle(body: VehicleInfo) {
    return this.http.post<any>(`${this.apiUri}/vehicles`, body);
  }
  public updateVehicle(body: VehicleData) {
    return this.http.put<any>(`${this.apiUri}/vehicles/${body.plateId}`, body, {
      observe: "response",
    });
  }
  public deleteVehicle(plateId: string, companyId: string) {
    return this.http.delete<any>(`${this.apiUri}/vehicles/${plateId}`, {
      params: { companyId },
      observe: "response",
    });
  }
  public getVehiclesFilters() {
    return this.http.get<VehiclesFilters>(`${this.apiUri}/vehicles/filters`);
  }
  public getVehiclesInfo(params: VehiclesInfoParams) {
    return this.http.get<{
      vehicles: Array<VehicleInfo>;
      lastKey: VehicleLastKey;
    }>(`${this.apiUri}/vehicles-info`, { params });
  }
  public getVehicleInfo(plateId: string) {
    return this.http.get<
      VehicleInfo & { boxId: string; declaredConsumption: string }
    >(`${this.apiUri}/vehicles-info/${plateId}`);
  }
  public updateVehicleInfo(plateId: string, body: VehicleInfoUpdate) {
    return this.http.put(`${this.apiUri}/vehicles-info/${plateId}`, body, {
      observe: "response",
    });
  }
  public importVehicleInfo(key: string) {
    return this.http.post<any>(
      `${this.apiUri}/vehicles-info/import`,
      { key },
      { observe: "response" }
    );
  }
  public getVehicleTelemetries(
    plateId: string | number,
    from: string,
    to: string,
    lastKey: { plateId: string; insertedAt: string }
  ) {
    return this.http.get<
      | {
          diagnostics: Diagnostics[] | RemoteDiagnostics[];
          lastKey?: { insertedAt: string; plateId: string };
        }
      | Diagnostics
      | RemoteDiagnostics
    >(`${this.apiUri}/diagnostics/${plateId}`, {
      params:
        from && to && lastKey
          ? {
              from,
              to,
              latestPlateId: lastKey.plateId,
              latestInsertedAt: lastKey.insertedAt,
            }
          : from && to
          ? { from, to }
          : null,
      observe: "response",
    });
  }
  public getVehicleDiagnostics(
    plateId: string | number,
    from: string,
    to: string,
    lastKey: { plateId: string; insertedAt: string }
  ) {
    return this.http.get<{
      telemetries: Telemetry[];
      lastKey?: { insertedAt: string; plateId: string };
    }>(`${this.apiUri}/telemetries/${plateId}`, {
      params:
        from && to && lastKey
          ? {
              from,
              to,
              latestPlateId: lastKey.plateId,
              latestInsertedAt: lastKey.insertedAt,
            }
          : from && to
          ? { from, to }
          : null,
      observe: "response",
    });
  }
  public getVehicleRemoteDiagnostics(
    plateId: string | number,
    from: string,
    to: string,
    lastKey: { plateId: string; insertedAt: string }
  ) {
    return this.http.get<
      | {
          diagnostics: Diagnostics[] | RemoteDiagnostics[];
          lastKey?: { insertedAt: string; plateId: string };
        }
      | Diagnostics
      | RemoteDiagnostics
    >(`${this.apiUri}/remote-diagnostics/${plateId}`, {
      params:
        from && to && lastKey
          ? {
              from,
              to,
              latestPlateId: lastKey.plateId,
              latestInsertedAt: lastKey.insertedAt,
            }
          : from && to
          ? { from, to }
          : null,
      observe: "response",
    });
  }
  public getVehicleVideo(plateId: string) {
    return this.http.get<VehicleVideo>(
      `${this.apiUri}/vehicles/${plateId}/video`
    );
  }
  public getVideos(plateId: string, params: VehicleVideoParams = {}) {
    return this.http.get<VehicleVideo[]>(
      `${this.apiUri}/vehicles/${plateId}/video`,
      { params }
    );
  }
  public requestVehicleVideo(plateId: string, timestamp: string) {
    return this.http.post<any>(
      `${this.apiUri}/vehicles/${plateId}/video-request`,
      { timestamp },
      { observe: "response" }
    );
  }
  public getVehiclesStats(params: {
    companyId: string;
    startDate: string;
    endDate: string;
  }) {
    return this.http.get<{ statistics: VehicleStats[] }>(
      `${this.apiUri}/vehicles/statistics`,
      { params }
    );
  }
  public getVehicleStats(plateId: string, startDate: string, endDate: string) {
    const params =
      startDate && endDate
        ? { startDate, endDate }
        : startDate
        ? { startDate }
        : {};
    return this.http.get<VehicleDailyStats>(
      `${this.apiUri}/vehicles/${plateId}/statistics`,
      { params }
    );
  }
  public getConsumptionHistory(
    plateId: string,
    type: string,
    lastKey: { plateId: string; typeInsertedAt: string }
  ) {
    const params = lastKey
      ? {
          latestPlateId: lastKey.plateId,
          latestInsertedAt: `${lastKey.typeInsertedAt}`,
        }
      : {};
    return this.http.get<{
      list: VehicleConsumption[];
      lastKey: { plateId: string; typeInsertedAt: string };
    }>(`${this.apiUri}/vehicles/${plateId}/consumptions/${type}`, { params });
  }
  public addConsumptionHistory(
    plateId: string,
    type: string,
    value: number,
    description: string,
    timestamp: number
  ) {
    return this.http.put<VehicleConsumption>(
      `${this.apiUri}/vehicles/${plateId}/consumptions`,
      { type, value, description, timestamp },
      { observe: "response" }
    );
  }
  public exportVehicles(params: VehiclesInfoParams) {
    console.log(params);
    return this.http.get(`${this.apiUri}/vehicles/export`, { params });
  }
  public syncronizeAssetManagement() {
    return this.http.get(`${this.apiUri}/integra`);
  }

  exportVehiclesStatistics(
    from: string,
    to: string,
    statistic: "distance" | "time",
    plateId: string[],
    area: string,
    company: string,
    type: string,
    detention: string,
    truckFitterId?: string
  ) {
    let params = {
      from,
      to,
      statistic,
      plateId,
      area,
      company,
      type,
      detention,
      truckFitterId,
    };
    if (params.plateId.length === 0) {
      delete params.plateId;
    }
    if (!params.area) {
      delete params.area;
    }
    if (!params.company) {
      delete params.company;
    }
    if (!params.type) {
      delete params.type;
    }
    if (!params.detention) {
      delete params.detention;
    }
    if (!params.truckFitterId) {
      delete params.truckFitterId;
    }
    return this.http.get(`${this.apiUri}/vehicles/statistics/export`, {
      params,
    });
  }

  public cloneVehicle(plateId: string, newVehicle: string, replate: boolean) {
    let body = {
      plateId,
      newVehicle,
      replate,
    };
    return this.http.put(`${this.apiUri}/vehicles/clone`, body, {
      observe: "response",
    });
  }

  public getVehiclesTypes() {
    return this.http.get(`${this.apiUri}/vehicles/type`, {
      observe: "response",
    });
  }

  public createVehiclesType(it: string, en: string) {
    let body = {
      it,
      en,
    };
    if (!body.en) {
      delete body.en;
    }
    return this.http.post(`${this.apiUri}/vehicles/type`, body, {
      observe: "response",
    });
  }

  public editVehiclesType(name: string, it: string, en: string) {
    let body = {
      it,
      en,
    };
    return this.http.put(`${this.apiUri}/vehicles/type/${name}`, body, {
      observe: "response",
    });
  }

  public deleteVehiclesType(it: string) {
    return this.http.delete(`${this.apiUri}/vehicles/type/${it}`, {
      observe: "response",
    });
  }

  // ***** //
  // AREAS //
  // ***** //

  public createArea(body: { name: string; radius: number }) {
    return this.http.put<{ areas: { name: string; radius: number }[] }>(
      `${this.apiUri}/areas`,
      body,
      { observe: "response" }
    );
  }
  public deleteArea(name: string) {
    return this.http.delete<any>(`${this.apiUri}/areas`, {
      params: { name },
      observe: "response",
    });
  }
  public getMunicipalities(
    region: string = null,
    province: string = null,
    municipalityName: string = null,
    municipalityCode: string = null
  ) {
    let params = {
      region,
      province,
      municipalityName,
      municipalityCode,
    };
    if (params.region === null) {
      delete params.region;
    }
    if (params.province === null) {
      delete params.province;
    }
    if (params.municipalityName === null) {
      delete params.municipalityName;
    }
    if (params.municipalityCode === null) {
      delete params.municipalityCode;
    }
    return this.http.get(`${this.apiUri}/municipalities`, {
      params,
      observe: "response",
    });
  }

  public getRegion(
    municipalityName: string = null,
    municipalityCode: string = null
  ) {
    let params = {
      municipalityName,
      municipalityCode,
    };
    if (params.municipalityName === null) {
      delete params.municipalityName;
    }
    if (params.municipalityCode === null) {
      delete params.municipalityCode;
    }
    return this.http.get(`${this.apiUri}/municipalities`, {
      params,
      observe: "response",
    });
  }

  // ***** //
  // BOXES //
  // ***** //

  upgradeFirmware(ids: Array<string>, file: File) {
    return null;
  }
  public getSmartboxes(params) {
    return this.http.get<SmartBoxList>(`${this.apiUri}/boxes`, {
      params: params,
    });
  }
  public createSmartbox(
    boxId: string,
    serialNumber: string,
    equipmentSerialNumber: string,
    chassisNumber: string,
    rate: number,
    dataType: string
  ) {
    if (equipmentSerialNumber !== "" && chassisNumber !== "") {
      return this.http.post(
        `${this.apiUri}/boxes`,
        {
          boxId,
          serialNumber,
          equipmentSerialNumber,
          rate,
          chassisNumber,
          dataType,
        },
        { observe: "response" }
      );
    } else if (equipmentSerialNumber === "" && chassisNumber !== "") {
      return this.http.post(
        `${this.apiUri}/boxes`,
        { boxId, serialNumber, rate, chassisNumber, dataType },
        { observe: "response" }
      );
    } else if (equipmentSerialNumber !== "" && chassisNumber === "") {
      return this.http.post(
        `${this.apiUri}/boxes`,
        { boxId, serialNumber, rate, equipmentSerialNumber, dataType },
        { observe: "response" }
      );
    } else {
      return this.http.post(
        `${this.apiUri}/boxes`,
        { boxId, serialNumber, rate, dataType },
        { observe: "response" }
      );
    }
  }
  public deleteSmartbox(id: number | string) {
    return this.http.delete(`${this.apiUri}/boxes/${id}`, {
      observe: "response",
    });
  }
  public associateBox(
    boxId: string,
    plateId: string,
    oldPlateId: string,
    equipmentSerialNumber: string,
    chassisNumber: string,
    rate: number,
    serialNumber: string,
    preTestingDate: string,
    deliveryDate: string,
    testingAndCommissioningDate: string,
    contractualTerm: number,
    billingFrequency: number,
    isTested: boolean,
    commissioningNumber: string,
    truckFitter: string,
    dataType: string
  ) {
    // const body = !oldPlateId || oldPlateId === '*' ? null :{ oldPlateId };
    let body = {
      oldPlateId,
      equipmentSerialNumber,
      chassisNumber,
      rate,
      serialNumber,
      preTestingDate,
      deliveryDate,
      testingAndCommissioningDate,
      contractualTerm,
      isTested,
      commissioningNumber,
      truckFitter,
      billingFrequency,
      dataType,
    };
    if (!oldPlateId || oldPlateId === "*") {
      delete body.oldPlateId;
    }
    if (!preTestingDate) {
      delete body.preTestingDate;
    }
    if (!deliveryDate) {
      delete body.deliveryDate;
    }
    if (!testingAndCommissioningDate) {
      delete body.testingAndCommissioningDate;
    }
    if (!contractualTerm) {
      delete body.contractualTerm;
    }
    if (!billingFrequency) {
      delete body.billingFrequency;
    }
    if (!isTested) {
      delete body.isTested;
    }
    if (!commissioningNumber) {
      delete body.commissioningNumber;
    }
    if (!dataType) {
      delete body.dataType;
    }
    return this.http.put(
      `${this.apiUri}/boxes/${boxId}/associate/${plateId}`,
      body
    );
  }
  public certificateBox(id: string | number) {
    return this.http.put(`${this.apiUri}/boxes/${id}/certificate`, null, {
      observe: "response",
    });
  }
  public setBoxToPlate(body: { boxId: string; plateId: string }) {
    return this.http.post<any>(`${this.apiUri}/boxes`, body);
  }
  public unsetBoxToPlate(boxId: string) {
    return this.http.delete<any>(`${this.apiUri}/boxes/${boxId}`);
  }
  public getPlates(plateId: string, truckFitterId?: string) {
    let params = {
      plateId: plateId.toUpperCase(),
      loadAll: "loadAll",
      truckFitterId,
    };
    if (!truckFitterId) delete params.truckFitterId;
    return this.http.get<{
      boxes: [{ plateId: string; id: string; companyId: string }];
    }>(`${this.apiUri}/boxes`, { params });
  }
  public exportBoxes(truckFitterId?: string) {
    if (truckFitterId) {
      return this.http.get(`${this.apiUri}/boxes/export`, {
        params: { truckFitterId },
      });
    } else {
      return this.http.get(`${this.apiUri}/boxes/export`);
    }
  }
  public dismiss(boxId: string) {
    return this.http.put<any>(
      `${this.apiUri}/boxes/${boxId}`,
      {},
      { observe: "response" }
    );
  }
  public vehicleTest(plateId: string) {
    return this.http.post<any>(`${this.apiUri}/boxes/test/${plateId}`, {});
  }

  // ***** //
  // PATHS //
  // ***** //

  public savePath(params: {
    name: string;
    plateId: string;
    from: number;
    to: number;
  }) {
    return this.http.post<any>(`${this.apiUri}/optimal-paths`, params, {
      observe: "response",
    });
  }
  public getOptimalPaths(params: { plateId?: string; name?: string }) {
    return this.http.get<OptimalPath[]>(`${this.apiUri}/optimal-paths`, {
      params,
    });
  }
  public createOptimalPath(body: OptimalPath) {
    return this.http.post<any>(`${this.apiUri}/optimal-paths`, body);
  }
  public deleteOptimalPath(userId: string, insertedAt: string) {
    return this.http.delete<any>(`${this.apiUri}/optimal-paths/${userId}`, {
      params: { insertedAt },
    });
  }
  public getPaths(params: PathsListParams) {
    return this.http.get<PathList>(`${this.apiUri}/paths`, { params });
  }
  public getPath(params: PathParams) {
    return this.http.get<Path>(`${this.apiUri}/path`, { params });
  }
  public exportPath(body: PathExport) {
    return this.http.post<{ export: string }>(
      `${this.apiUri}/path/export`,
      body
    );
  }
  public exportPathKML(body: PathExportKML) {
    return this.http.post<{ export: string }>(
      `${this.apiUri}/path/exportKml`,
      body
    );
  }
  public exportPaths(body: PathsExport) {
    return this.http.post<{ export: string }>(
      `${this.apiUri}/paths/export`,
      body
    );
  }

  public exportIotData(body: ExportIotData) {
    return this.http.post<{ export: string }>(
      `${this.apiUri}/path/exportIotData`,
      body
    );
  }

  // **** //
  // POIs //
  // **** //

  public getPoiList(params: PoiParams) {
    return this.http.get<Array<PoiDetails>>(`${this.apiUri}/poi`, { params });
  }
  public getPoi(hashKey: string, name: string) {
    return this.http.get<PoiDetails>(`${this.apiUri}/poi/${hashKey}`, {
      params: { name },
    });
  }
  public createPoi(body: Poi) {
    return this.http.post<any>(`${this.apiUri}/poi`, body);
  }
  public updatePoi(hashKey: string, oldName: string, body: Poi) {
    return this.http.put<any>(`${this.apiUri}/poi/${hashKey}`, body, {
      params: { oldName },
    });
  }
  public deletePoi(hashKey: any, name: string) {
    return this.http.delete<UpdateResult>(`${this.apiUri}/poi/${hashKey}`, {
      params: { name },
      observe: "response",
    });
  }
  public getPoiRoutes(params: PoiRouteParams) {
    return this.http.get<Array<PoiRoute>>(`${this.apiUri}/poi/routes`, {
      params,
    });
  }
  public getPoiRoute(routeId: number | string) {
    return this.http.get<Array<PoiDetails>>(
      `${this.apiUri}/poi/routes/${routeId}`
    );
  }
  public createPoiRoute(body: {
    name: string;
    poiName: string;
    poiType: string;
    lat: number;
    lon: number;
  }) {
    return this.http.post<any>(`${this.apiUri}/poi/routes`, body);
  }
  public getPoiRouteDetails(routeId: string) {
    return this.http.get<Array<PoiDetails>>(
      `${this.apiUri}/poi/routes/${routeId}`
    );
  }
  public updatePoiRoute(routeId: string | number, body: PoiRoute) {
    return this.http.put<any>(`${this.apiUri}/poi/routes/${routeId}`, body, {
      observe: "response",
    });
  }
  public updatePoiRouteName(routeId: string | number, name: string) {
    return this.http.put<any>(`${this.apiUri}/poi/routes/${routeId}`, { name });
  }
  public deletePoiRoute(routeId: string | number) {
    return this.http.delete<UpdateResult>(
      `${this.apiUri}/poi/routes/${routeId}`,
      { observe: "response" }
    );
  }
  public addPoiToRoute(routeId: string | number, body: Poi) {
    return this.http.put<any>(`${this.apiUri}/poi/routes/${routeId}`, body);
  }
  public movePoiRouteUp(routeId: any, routePosition: any) {
    return this.http.put<any>(
      `${this.apiUri}/poi/routes/${routeId}/positions/${routePosition}/up`,
      null,
      { observe: "response" }
    );
  }
  public movePoiRouteDown(routeId: any, routePosition: any) {
    return this.http.put<any>(
      `${this.apiUri}/poi/routes/${routeId}/positions/${routePosition}/down`,
      null,
      { observe: "response" }
    );
  }
  public importPois(routeId: number, routeName: string, key: string) {
    let params = new HttpParams();
    if (routeId !== null && routeId >= 0) {
      params = params.set("routeId", `${routeId}`);
    }
    if (routeName) {
      params = params.set("routeName", `${routeName}`);
    }
    return this.http.post<UpdateResult>(
      `${this.apiUri}/poi/batch`,
      { key },
      { params, observe: "response" }
    );
  }
  public importKmlPois(key: string) {
    let body = {
      key,
    };
    return this.http.post<any>(`${this.apiUri}/poi/import`, body);
  }

  // **** //
  // MAPS //
  // **** //

  public exportMap(body: MapExport) {
    return this.http.post<{ export: string }>(
      `${this.apiUri}/map/export`,
      body
    );
  }

  // **** //
  // KPIs //
  // **** //

  public getKpi(params: KpiFilters) {
    return this.http.get<any>(`${this.apiUri}/kpi`, { params });
  }

  // ********* //
  // COMPANIES //
  // ********* //

  public getCompanies() {
    return this.http.get<{ companies: Company[] }>(`${this.apiUri}/companies`);
  }
  public getCompany(companyId: string) {
    return this.http.get<any>(`${this.apiUri}/companies/${companyId}`);
  }
  public createCompany(company: Company) {
    return this.http.put(`${this.apiUri}/companies`, company, {
      observe: "response",
    });
  }
  public updateCompany(company: Company) {
    return this.http.post(`${this.apiUri}/companies`, company, {
      observe: "response",
    });
  }
  public deleteCompany(companyId: string) {
    return this.http.delete(`${this.apiUri}/companies/${companyId}`, {
      observe: "response",
    });
  }
  public recoverData(
    companyId: string,
    plates: string[],
    from: number,
    to: number
  ) {
    let body = {
      companyId,
      plates,
      from,
      to,
    };
    return this.http.post(`${this.apiUri}/data-retention`, body, {
      observe: "response",
    });
  }
  public blockCompany(companyId: string, lock: string) {
    let params = {
      lock,
    };
    return this.http.put(
      `${this.apiUri}/companies/${companyId}/state`,
      {},
      { params, observe: "response" }
    );
  }

  public unlockCompany(companyId: string, unlock: string) {
    let params = {
      unlock,
    };
    return this.http.put(
      `${this.apiUri}/companies/${companyId}/state`,
      {},
      { params, observe: "response" }
    );
  }

  // ***** //
  // USERS //
  // ***** //

  public getUsers() {
    return this.http.get<{ users: User[] }>(`${this.apiUri}/users`);
  }
  public getUser(userId: string) {
    return this.http.get<CustomerDetails>(`${this.apiUri}/users/${userId}`);
  }
  public getMe(userId: string) {
    return this.http.get<User>(`${this.apiUri}/users/${userId}`);
  }
  public createUser(user: {
    companyId: string;
    email: string;
    tmpPassword: string;
    role: string;
    reports: string[];
    mfa: boolean;
  }) {
    return this.http.put<{ userId: string }>(`${this.apiUri}/users`, user, {
      observe: "response",
    });
  }
  public updateUser(
    user:
      | {
          userId: string;
          companyId: string;
          email?: string;
          role: string;
          mfa?: boolean;
          fullName?: string;
          cf?: string;
          date?: string;
          place?: string;
          terms?: boolean;
          extraEu?: boolean;
          marketing?: boolean;
        }
      | { email: string; newPassword: boolean }
  ) {
    return this.http.post(`${this.apiUri}/users`, user, {
      observe: "response",
    });
  }
  public deleteUser(userId: string, companyId: string, email: string) {
    return this.http.delete(`${this.apiUri}/users/${userId}`, {
      params: { companyId, email },
      observe: "response",
    });
  }
  public lastLogin(userId: string, token: string) {
    const headers: HttpHeaders = new HttpHeaders().set("Authorization", token);
    return this.http.put(
      `${this.apiUri}/users/${userId}/lastlogin`,
      {},
      { headers: headers, observe: "response" }
    );
  }
  public blockUser(userId: string, lock: string) {
    let params = {
      lock,
    };
    return this.http.put(
      `${this.apiUri}/users/${userId}/state`,
      {},
      { params, observe: "response" }
    );
  }

  public unlockUser(userId: string, unlock: string) {
    let params = {
      unlock,
    };
    return this.http.put(
      `${this.apiUri}/users/${userId}/state`,
      {},
      { params, observe: "response" }
    );
  }

  // ********* //
  // CUSTOMERS //
  // ********* //

  public getCustomers() {
    return this.http.get<Array<CustomerMetadata>>(
      `${this.apiUri}/users/customers`
    );
  }
  public getCustomer(userId: string) {
    return this.http.get<CustomerDetails>(
      `${this.apiUri}/users/customers/${userId}`
    );
  }
  public updateCustomerAreas(
    userId: string | number,
    body: { areas: Array<CustomerArea> }
  ) {
    return this.http.put<any>(
      `${this.apiUri}/users/customers/${userId}/areas`,
      body
    );
  }

  // ****** //
  // AUDITS //
  // ****** //

  public getAudits(params: AuditsParams) {
    return this.http.get<{ audits: Array<Audits>; lastKey: AuditsLastKey }>(
      `${this.apiUri}/audits`,
      { params }
    );
  }
  public getAuditsUsers() {
    return this.http.get<Array<{ userId: string }>>(
      `${this.apiUri}/audits/users`
    );
  }
  public getAuditsPaths() {
    return this.http.get<Array<{ path: string }>>(
      `${this.apiUri}/audits/paths`
    );
  }
  public exportAudits(body: AuditsExport) {
    return this.http.post<{ export: string }>(
      `${this.apiUri}/audits/export`,
      body
    );
  }

  // **** //
  // LOGS //
  // **** //

  public getLogs(params: LogsParams) {
    return this.http.get<{ logs: Array<Logs>; lastKey: LogsLastKey }>(
      `${this.apiUri}/logs`,
      { params }
    );
  }
  public getLogsEvents(eventType?: string) {
    return this.http.get<Array<{ eventType: string }>>(
      `${this.apiUri}/logs/events`,
      { params: eventType ? { eventType } : {} }
    );
  }
  public exportLogs(body: LogsExport) {
    return this.http.post<{ export: string }>(
      `${this.apiUri}/logs/export`,
      body
    );
  }

  // ********* //
  // ANOMALIES //
  // ********* //

  public getPositionsAnomalies(
    plateId: string,
    from: string,
    to: string,
    type: "plc" | "fms" | "counter",
    lastKey: { plateId: string; insertedAt: string } = null
  ) {
    const params = {
      ...(plateId ? { plateId } : {}),
      ...(from ? { from } : {}),
      ...(to ? { to } : {}),
      ...(type ? { type } : { type: "fms" }),
      ...(lastKey
        ? { lastPlateId: lastKey.plateId, lastInsertedAt: lastKey.insertedAt }
        : {}),
    };
    return this.http.get<
      AppAnomalyResponse | { anomalies: AppAnomalyCounter[] }
    >(`${this.apiUri}/anomalies`, { params });
  }

  public deleteAnomaliesCounter(plateId: string) {
    return this.http.delete(`${this.apiUri}/anomalies/${plateId}`);
  }

  public deleteAnomaliesCounters() {
    return this.http.delete(`${this.apiUri}/anomalies/all`);
  }

  // ******** //
  // COMMANDS //
  // ******** //

  public sendCommand(plateId: string, name: string, value?: any, zone?: any) {
    let body;
    if (value === null) {
      body = {
        name: name,
      };
    } else if (zone === null) {
      body = {
        name: name,
        value: value,
      };
    } else {
      body = {
        name: name,
        value: value,
        zone: zone,
      };
    }
    return this.http.post(`${this.apiUri}/vehicles/${plateId}/commands`, body, {
      observe: "response",
    });
  }

  public getCommands(
    plateId: string,
    createdAt: string,
    from: string,
    to: string,
    loadAll: string
  ) {
    const params = {
      createdAt,
      from,
      to,
      loadAll,
    };
    if (params.loadAll === undefined) {
      delete params.loadAll;
    }
    return this.http.get<{ commands: Array<Commands>; lastKey: any }>(
      `${this.apiUri}/vehicles/${plateId}/commands`,
      { params }
    );
  }

  public getCommandsState(plateId: string) {
    return this.http.get<{ commands: any }>(
      `${this.apiUri}/vehicles/${plateId}/commands/state`
    );
  }

  public enableCommands(plateId: string, commands: string[]) {
    let body = {
      commands,
    };
    return this.http.put<{}>(
      `${this.apiUri}/vehicles/${plateId}/commands`,
      body,
      { observe: "response" }
    );
  }

  // ************** //
  // SERVICE ORDERS //
  // ************** //

  public createServiceOrder(
    name: string,
    plateId: string[],
    type: string,
    startDate: number,
    endDate: number,
    areas: any[]
  ) {
    let body = {
      name,
      plateId,
      type,
      startDate,
      endDate,
      areas,
    };
    return this.http.post(`${this.apiUri}/service-orders`, body, {
      observe: "response",
    });
  }

  public getServiceOrders(from: string, to: string) {
    const params = {
      from,
      to,
    };
    return this.http.get<{ ods: any[] }>(`${this.apiUri}/service-orders`, {
      params,
    });
  }

  public updateServiceOrder(
    name: string,
    plateId: string[],
    startDate: number,
    endDate: number,
    areas: any[],
    createdAt: number
  ) {
    let body = {
      name,
      plateId,
      startDate,
      endDate,
      areas,
    };
    return this.http.put(`${this.apiUri}/service-orders/${createdAt}`, body, {
      observe: "response",
    });
  }

  public deleteServiceOrder(createdAt: number) {
    return this.http.delete(`${this.apiUri}/service-orders/${createdAt}`, {
      observe: "response",
    });
  }

  public createServiceOrderArea(areas: any[]) {
    let body = {
      areas,
    };
    return this.http.post(`${this.apiUri}/service-orders/areas`, body, {
      observe: "response",
    });
  }

  public getServiceOrderAreas() {
    return this.http.get<{ areas: ServiceOrderAreas[] }>(
      `${this.apiUri}/service-orders/areas`,
      { observe: "response" }
    );
  }

  public updateServiceOrderArea(
    name: string,
    coordinates: any,
    isModel: boolean
  ) {
    let body = {
      coordinates,
      isModel,
    };
    return this.http.put(`${this.apiUri}/service-orders/areas/${name}`, body, {
      observe: "response",
    });
  }

  public deleteServiceOrderArea(name: string) {
    return this.http.delete(`${this.apiUri}/service-orders/areas/${name}`, {
      observe: "response",
    });
  }

  public getSingleServiceOrderAreas(createdAt: number) {
    return this.http.get<{ areas: ServiceOrderAreas[] }>(
      `${this.apiUri}/service-orders/${createdAt}/areas`,
      { observe: "response" }
    );
  }

  public getSingleServiceOrder(createdAt: number) {
    return this.http.get(`${this.apiUri}/service-orders/${createdAt}`, {
      observe: "response",
    });
  }

  public getSingleServiceOrderStats(
    createdAt: number,
    plateId: string[],
    area: string[],
    from: string,
    to: string,
    position: string
  ) {
    let params = {
      plateId,
      area,
      from,
      to,
      position,
    };
    if (params.plateId === undefined || params.plateId.length === 0) {
      delete params.plateId;
    }
    if (params.area === undefined || params.area.length === 0) {
      delete params.area;
    }
    if (params.from === "" || params.from === undefined) {
      delete params.from;
    }
    if (params.to === "" || params.to === undefined) {
      delete params.to;
    }
    if (params.position === "" || params.position === undefined) {
      delete params.position;
    }
    return this.http.get<{ stats: ServiceOrderStats[] }>(
      `${this.apiUri}/service-orders/${createdAt}/stats`,
      { params, observe: "response" }
    );
  }

  public getVehiclesEnabledCommands(plates: string) {
    let body = {
      plates,
    };
    return this.http.post(`${this.apiUri}/vehicles-commands`, body, {
      observe: "response",
    });
  }

  // ******* //
  // REPORTS //
  // ******* //

  public getReports(userEmail?: string) {
    console.log("encode", encodeURIComponent(userEmail));
    return this.http.get(`${this.apiUri}/reports`, {
      params: {
        userEmail: userEmail ? encodeURIComponent(userEmail) : undefined,
      },
      observe: "response",
    });
  }

  public createReport(
    type: string,
    name: string,
    periodics: any,
    enabled: boolean,
    conditions: any,
    authorRole?: string,
    authorEmail?: string
  ) {
    let body = {
      type,
      name,
      periodics,
      enabled,
      conditions,
      authorRole,
      authorEmail,
    };
    if (body.conditions === undefined) {
      delete body.conditions;
    }
    if (body.authorRole === undefined) {
      delete body.authorRole;
    }
    if (body.authorEmail === undefined) {
      delete body.authorEmail;
    }
    return this.http.post(`${this.apiUri}/reports`, body, {
      observe: "response",
    });
  }

  public deleteReport(createdAt: string) {
    return this.http.delete(`${this.apiUri}/reports/${createdAt}`, {
      observe: "response",
    });
  }

  public putReport(
    createdAt: string,
    type: string,
    periodics: string,
    enabled: string,
    conditions: string,
    sendNow: boolean
  ) {
    let body = {
      type,
      periodics,
      enabled,
      conditions,
      sendNow,
    };
    if (body.conditions === undefined) {
      delete body.conditions;
    }
    return this.http.put(`${this.apiUri}/reports/${createdAt}`, body, {
      observe: "response",
    });
  }

  // ****************** //
  // KILOMETRIC REFUND //
  // ***************** //

  public getTravelRefund(
    plateId: string,
    year: string,
    month: string,
    day?: string
  ) {
    const params = {
      plateId,
      year,
      month,
      day: day ? day : "",
    };
    return this.http.get<{ travels: JourneyDetails[] }>(
      `${this.apiUri}/travel-refund`,
      { params }
    );
  }
  public getUserTravel(plateId: string, year: string, month: string) {
    const params = {
      plateId,
      year,
      month,
    };
    return this.http.get<
      { travels: JourneyDetails[] } | { error: { error: string } }
    >(`${this.apiUri}/user-travels`, { params, observe: "response" });
  }

  public putUserTravel(plateId: string, body: JourneyDetails) {
    return this.http.put(`${this.apiUri}/user-travels/${plateId}`, body, {
      observe: "response",
    });
  }

  public exportTravels(plateId: string, year: string, month: string) {
    return this.http.get(`${this.apiUri}/user-travels/${plateId}`, {
      params: { year: year, month: month },
      observe: "response",
    });
  }

  // ****************** //
  //   ADMINISTRATION   //
  // ****************** //

  public getAdministrationSmartboxes(params?) {
    return this.http.get<SmartBoxList>(`${this.apiUri}/administration/boxes`, {
      params: params,
    });
  }

  public exportBoxesAdministration(
    commissioningNumber: string,
    serialNumber: string,
    companyId: string,
    truckFitter: string,
    plateId: string,
    chassisNumber: string
  ) {
    return this.http.get(`${this.apiUri}/administration/boxes/export`, {
      params: {
        commissioningNumber,
        serialNumber,
        companyId,
        truckFitter,
        plateId,
        chassisNumber,
      },
    });
  }

  public getAdministrationPlates(plateId: string) {
    return this.http.get<{ boxes: [{ plateId: string; id: string }] }>(
      `${this.apiUri}/administration/boxes`,
      { params: { plateId: plateId.toUpperCase(), loadAll: "loadAll" } }
    );
  }

  // ****************** //
  //         EDO        //
  // ****************** //

  public getEdoSmartboxes(params?) {
    return this.http.get<SmartBoxList>(`${this.apiUri}/edo/boxes`, {
      params: params,
    });
  }

  public getEdoTelemetries(plateId: string, params?) {
    return this.http.get<{ diagnostics: Diagnostics[] }>(
      `${this.apiUri}/edo/diagnostics/${plateId}`,
      { params: params ? params : null }
    );
  }

  public exportEdoData(from: string, to: string, plateId: string) {
    return this.http.get(`${this.apiUri}/edo/exportData`, {
      params: { from, to, plateId },
    });
  }

  // ****************** //
  //      DB ADMIN      //
  // ****************** //

  public startDataMigrationToS3(from: number, to: number, companyId: string) {
    let body = {
      from,
      to,
      companyId,
    };
    return this.http.post(`${this.apiUri}/migration-to-s3`, body, {
      observe: "response",
    });
  }

  // ****************** //
  //  LIST TELEMETRIES  //
  // ****************** //

  public listTelemetries(params) {
    return this.http.get(`${this.apiUri}/diagnostics`, { params: params });
  }

  // ****************** //
  // REMOTE DIAGNOSTICS //
  // ****************** //

  // public createSession(body: {plateId: string, startTime: string, command: number}) {
  //   return this.http.put(`${this.apiUri}/sessions`, body, { observe: 'response' });
  // }

  public getRemoteSessions(params: {
    from?: string;
    to?: string;
    plateId?: string;
    last?: string;
  }) {
    return this.http.get<{ sessions: RemoteSession[] }>(
      `${this.apiUri}/sessions`,
      { params }
    );
  }

  // *********************** //
  //  PRODUCTION DASHBOARDS  //
  // *********************** //

  public getProductionInfo(params) {
    return this.http.get<any>(`${this.apiUri}/production-dashboard`, {
      params: params,
    });
  }

  // *********************** //
  //  QuickSight DASHBOARDS  //
  // *********************** //

  public getDashboardEmbedUrl(companyDashboardId: string) {
    return this.http.get<any>(`${this.apiUri}/dashboard/${companyDashboardId}`);
  }

  /**
   * Post the request for a presigned URL for uploading files to S3
   * @param fileName The name of the file being uploaded
   * @param importType The type of process for which the file is being uploaded
   * @returns A response containing the presigned URL for uploading the file
   */
  public postAnalysiData(fileName: string, importType: string) {
    return this.http.post<{ presignedUrl: string }>(
      `${this.apiUri}/analysis/import`,
      {
        fileName,
        importType,
      }
    );
  }

  /**
   * Get the list of imported analysis files
   * @returns A list of imported files with their details
   */
  public getAnalysisImports() {
    return this.http.get<any>(`${this.apiUri}/analysis/imports`);
  }
}
