import { NgModule } from '@angular/core';
import { PagerComponent } from '../components/layout/pager/pager.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazySelectComponent } from '../components/layout/lazy-select/lazy-select.component';
import { DrivingStylePipe } from '../pipes/driving-style/driving-style.pipe';
import { GoogleAutocompleteComponent } from '../components/google-autocomplete/google-autocomplete.component';
import { TotTimePipe } from '../pipes/tot-time/tot-time.pipe';
import { CompanyLabelPipe } from '../pipes/company-label/company-label.pipe';
import { MapVehicleVideoComponent } from '../components/map-vehicle-video/map-vehicle-video.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';

@NgModule({
  declarations: [
    PagerComponent,
    LazySelectComponent,
    DrivingStylePipe,
    TotTimePipe,
    CompanyLabelPipe,
    GoogleAutocompleteComponent,
    MapVehicleVideoComponent,
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PagerComponent,
    LazySelectComponent,
    DrivingStylePipe,
    CompanyLabelPipe,
    TotTimePipe,
    GoogleAutocompleteComponent,
    MapVehicleVideoComponent,
    ConfirmationModalComponent
  ],
  providers: [
    TotTimePipe,
  ]
})
export class SharedModule {
  constructor() {}
}
