import { Injectable, Injector } from "@angular/core";
import { Overlay, OverlayRef, OverlayConfig } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { ModalComponent } from "../../components/overlays/modal/modal.component";
import { SidebarComponent } from "../../components/overlays/sidebar/sidebar.component";
import { ToastComponent } from "src/app/components/overlays/toast/toast.component";
import { MenuComponent } from "src/app/components/overlays/menu/menu.component";
import { OverlayComponentRef, OverlayData } from "src/app/utils/types";
import { LoaderComponent } from "src/app/components/overlays/loader/loader.component";

@Injectable({
  providedIn: "root",
})
export class OverlayService {
  public overlayRef: OverlayRef | null;
  private currentModal: OverlayComponentRef;
  private sidebar: OverlayComponentRef;
  private loader: OverlayComponentRef;
  private isLoading = false;

  constructor(private overlay: Overlay, private parentInjector: Injector) {}

  showModal(data: OverlayData, closeCurrent: boolean = true) {
    if (!!this.currentModal) {
      if (
        !window.location.pathname.includes("vehicles") &&
        !window.location.pathname.includes("box") &&
        closeCurrent
      ) {
        this.currentModal.close();
      }
    }

    const overlayRef = this.overlay.create(
      new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        hasBackdrop: true,
        backdropClass: "cdk-theme-backdrop",
        scrollStrategy: this.overlay.scrollStrategies.block(),
      })
    );
    this.currentModal = new OverlayComponentRef(overlayRef);
    const tokens = new WeakMap();
    tokens.set(OverlayData, data);
    tokens.set(OverlayComponentRef, this.currentModal);
    const injector = new PortalInjector(this.parentInjector, tokens);
    overlayRef.attach(new ComponentPortal(ModalComponent, null, injector));
    return this.currentModal;
  }

  closeModal() {
    if (this.currentModal) {
      this.currentModal.close();
    }
  }

  showToast(data: OverlayData) {
    const overlayRef = this.overlay.create(
      new OverlayConfig({
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .top("1rem"),
        hasBackdrop: false,
        scrollStrategy: this.overlay.scrollStrategies.noop(),
      })
    );
    const toast = new OverlayComponentRef(overlayRef);
    const tokens = new WeakMap();
    tokens.set(OverlayData, data);
    tokens.set(OverlayComponentRef, toast);
    const injector = new PortalInjector(this.parentInjector, tokens);
    overlayRef.attach(new ComponentPortal(ToastComponent, null, injector));
    return toast;
  }

  showContextMenu(parent: HTMLElement, data: OverlayData) {
    const overlayRef = this.overlay.create(
      new OverlayConfig({
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(parent)
          .withPositions([
            {
              originX: "end",
              originY: "bottom",
              overlayX: "end",
              overlayY: "top",
            },
          ])
          .withPush(false),
        hasBackdrop: true,
        backdropClass: "cdk-transparent-backdrop",
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        width: "auto",
        height: "auto",
      })
    );
    const menu = new OverlayComponentRef(overlayRef);
    const tokens = new WeakMap();
    tokens.set(OverlayData, data);
    tokens.set(OverlayComponentRef, menu);
    const injector = new PortalInjector(this.parentInjector, tokens);
    overlayRef.attach(new ComponentPortal(MenuComponent, null, injector));
    return menu;
  }

  showSidebar(data: OverlayData) {
    this.closeSidebar();
    const overlayRef = this.overlay.create(
      new OverlayConfig({
        positionStrategy: this.overlay.position().global(),
        hasBackdrop: true,
        backdropClass: "cdk-theme-backdrop",
        scrollStrategy: this.overlay.scrollStrategies.block(),
      })
    );
    this.sidebar = new OverlayComponentRef(overlayRef);
    const tokens = new WeakMap();
    tokens.set(OverlayData, data);
    tokens.set(OverlayComponentRef, this.sidebar);
    const injector = new PortalInjector(this.parentInjector, tokens);
    overlayRef.attach(new ComponentPortal(SidebarComponent, null, injector));
    return this.sidebar;
  }

  closeSidebar() {
    if (!!this.sidebar) {
      this.sidebar.close();
    }
  }

  public onLoading(data: OverlayData = { text: "" }) {
    if (!this.isLoading) {
      this.isLoading = true;
      const overlayRef = this.overlay.create(
        new OverlayConfig({
          positionStrategy: this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically(),
          hasBackdrop: true,
          backdropClass: "cdk-theme-backdrop",
          scrollStrategy: this.overlay.scrollStrategies.block(),
        })
      );
      this.loader = new OverlayComponentRef(overlayRef);
      const tokens = new WeakMap();
      tokens.set(OverlayData, data);
      tokens.set(OverlayComponentRef, this.loader);
      const injector = new PortalInjector(this.parentInjector, tokens);
      overlayRef.attach(new ComponentPortal(LoaderComponent, null, injector));
    }
  }

  public offLoading() {
    if (this.isLoading) {
      this.loader.close();
      this.isLoading = false;
    }
  }
}
