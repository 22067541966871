import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PATHS, ROUTES } from './app.routes';
import { MainComponent } from './components/layout/main/main.component';
import { ErrorComponent } from './pages/error/error.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthService } from './services/auth/auth.service';
import { LoginEDOComponent } from './pages/loginEDO/loginEDO.component';

const routes: Routes = [
	// Login Page
	{ path: ROUTES.auth.login, pathMatch: 'full', component: LoginComponent },
	//{ path: ROUTES.authEDO.loginEDO, pathMatch: 'full', component: LoginComponent },

	{ path: '', pathMatch: 'full', redirectTo: PATHS.login },
	//{ path: '', pathMatch: 'full', redirectTo: PATHS.loginEDO },

	{
		path: '',
		canActivate: [AuthService],
		component: MainComponent,
		children: [
			{
				path: ROUTES.auth.login, component: LoginComponent
			},
			{
				path: ROUTES.authEDO.loginEDO, component: LoginEDOComponent
			},
			{
				path: ROUTES.dashboard.base,
				children: [{ path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) }]
			},
			{
				path: ROUTES.productionDashboard.base,
				children: [{ path: '', loadChildren: () => import('./pages/production-dashboard/production-dashboard.module').then(m => m.ProductionDashboardModule) }]
			},
			{
				path: ROUTES.quicksight.base,
				children: [{ path: '', loadChildren: () => import('./pages/quicksight/quicksight.module').then(m => m.QuicksightModule) }]
			},
			{
				path: ROUTES.customers.base,
				children: [{ path: '', loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule) }]
			},
			{
				path: ROUTES.box.base,
				children: [{ path: '', loadChildren: () => import('./pages/box/box.module').then(m => m.BoxModule) }]
			},
			{
				path: ROUTES.audit.base,
				children: [{ path: '', loadChildren: () => import('./pages/audit/audit.module').then(m => m.AuditModule) }]
			},
			{
				path: ROUTES.kpi.base,
				children: [{ path: '', loadChildren: () => import('./pages/kpi/kpi.module').then(m => m.KpiModule) }]
			},
			{
				path: ROUTES.map.base,
				children: [{ path: '', loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule) }]
			},
			{
				path: ROUTES.path.base,
				children: [{ path: '', loadChildren: () => import('./pages/path/path.module').then(m => m.PathModule) }]
			},
			{
				path: ROUTES.vehicles.base,
				children: [{ path: '', loadChildren: () => import('./pages/vehicles/vehicles.module').then(m => m.VehiclesModule) }]
			},
			{
				path: ROUTES.drivers.base,
				children: [{ path: '', loadChildren: () => import('./pages/drivers/drivers.module').then(m => m.DriversModule) }]
			},
			{
				path: ROUTES.users.base,
				children: [{ path: '', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule) }]
			},
			{
				path: ROUTES.areas.base,
				children: [{ path: '', loadChildren: () => import('./pages/areas/areas.module').then(m => m.AreasModule) }]
			},
			{
				path: ROUTES.poi.base,
				children: [{ path: '', loadChildren: () => import('./pages/poi/poi.module').then(m => m.PoiModule) }]
			},
			{
				path: ROUTES.logs.base,
				children: [{ path: '', loadChildren: () => import('./pages/logs/logs.module').then(m => m.LogsModule) }]
			},
			{
				path: ROUTES.video.base,
				children: [{ path: '', loadChildren: () => import('./pages/videos/videos.module').then(m => m.VideosModule) }]
			},
			{
				path: ROUTES.telemetries.base,
				children: [{ path: '', loadChildren: () => import('./pages/telemetries/telemetries.module').then(m => m.TelemetriesModule) }]
			},
			{
				path: ROUTES.diagnostics.base,
				children: [{ path: '', loadChildren: () => import('./pages/diagnostics/diagnostics.module').then(m => m.DiagnosticsModule) }]
			},
			{
				path: ROUTES.anomalies.base,
				children: [{ path: '', loadChildren: () => import('./pages/anomalies/anomalies.module').then(m => m.AnomaliesModule) }]
			},
			{
				path: ROUTES.notifications.base,
				children: [{ path: '', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule) }]
			},
			{
				path: ROUTES.commands.base,
				children: [{ path: '', loadChildren: () => import('./pages/commands/commands.module').then(m => m.CommandsModule) }]
			},
			{
				path: ROUTES.serviceOrders.base,
				children: [{ path: '', loadChildren: () => import('./pages/service-orders/service-orders.module').then(m => m.ServiceOrdersModule) }]
			},
			{
				path: ROUTES.reports.base,
				children: [{ path: '', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) }]
			},
			{
				path: ROUTES.routePlanning.base,
				children: [{ path: '', loadChildren: () => import('./pages/route-planning/route-planning.module').then(m => m.RoutePlanningModule) }]
			},
			{
				path: ROUTES.carSharing.base,
				children: [{ path: '', loadChildren: () => import('./pages/car-sharing/car-sharing.module').then(m => m.CarSharingModule) }]
			},
			{
				path: ROUTES.kilometricRefund.base,
				children: [{ path: '', loadChildren: () => import('./pages/kilometric-refund/kilometric-refund.module').then(m => m.KilometricRefundModule) }]
			},
			{
				path: ROUTES.administration.base,
				children: [{ path: '', loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdministrationModule) }]
			},
			{
				path: ROUTES.edoDevices.base,
				children: [{ path: '', loadChildren: () => import('./pages/edo-devices/edo-devices.module').then(m => m.EdoDevicesModule) }]
			},
			{
				path: ROUTES.dbAdministration.base,
				children: [{ path: '', loadChildren: () => import('./pages/db-administration/db-administration.module').then(m => m.DbAdministrationModule) }]
			},
			{
				path: ROUTES.telemetriesList.base,
				children: [{ path: '', loadChildren: () => import('./pages/telemetries-list/telemetries-list.module').then(m => m.TelemetriesListModule) }]
			},
			{
				path: ROUTES.remoteTelemetries.base,
				children: [{ path: '', loadChildren: () => import('./pages/remote-telemetries/remote-telemetries.module').then(m => m.RemoteTelemetriesModule) }]
			},
			{
				path: ROUTES.remoteSessionsList.base,
				children: [{ path: '', loadChildren: () => import('./pages/remote-sessions-list/remote-sessions-list.module').then(m => m.RemoteSessionsListModule) }]
			},
		]
	},
	{ path: '**', component: ErrorComponent } // Error
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
