import { Component, OnInit } from '@angular/core';
import { OverlayData, OverlayComponentRef } from 'src/app/utils/types';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(readonly data: OverlayData, readonly ref: OverlayComponentRef) { }

  ngOnInit() {
    this.data.width = this.data.width ? `${this.data.width}px` : '8rem';
    this.ref.backdropClick.subscribe(e => this.ref.close());
  }
}
