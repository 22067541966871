import { Component, OnInit } from '@angular/core';
import { AnimationState, OverlayData, OverlayComponentRef } from 'src/app/utils/types';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  public animationState: AnimationState;

  constructor(readonly data: OverlayData, readonly ref: OverlayComponentRef) { }

  ngOnInit() {
    this.animationState = 'open';
  }

  close() {
    this.animationState = 'close';
  }
}