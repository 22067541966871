import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Auth, Storage, Analytics, Logger } from 'aws-amplify';

if (environment.production) {
  enableProdMode();
  // if (location.origin.endsWith('.mgh-systems.com')) {
  //   environment.amplify.Auth.cookieStorage.domain = location.origin;
  //   environment.amplify.Auth.cookieStorage.secure = true;
  // }
// } else if (location.origin.startsWith('http://127.0.0.1') || location.origin.startsWith('http://localhost')) {
//   environment.amplify.Auth.cookieStorage.domain = location.origin;
}

Auth.configure(environment.amplify.Auth);
Storage.configure(environment.amplify.Storage);
Analytics.configure(environment.amplify.Analytics);
Logger.LOG_LEVEL = environment.amplify.Logger.LOG_LEVEL;

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {})
  .catch(err => console.error(err));