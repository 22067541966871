<div [@fadeBottom]="animationState" (@fadeBottom.done)="onFadeFinished($event)" class="container w-full max-w-sm absolute bottom-0 md:bottom-auto inset-x-0 mx-auto" [ngStyle]="{'width': data.width}">
  <div class="bg-0 border-30 md:border rounded-t md:rounded-b w-full">
    <div *ngIf="data.text" class="bg-0 rounded-t p-2 border-b border-30 flex justify-between">
      <p class="font-bold text-lg px-2">
        <fa-icon [icon]="data.icon" class="mr-2 opacity-75"></fa-icon> {{ data.text }}
      </p>
      <fa-icon icon="times" *ngIf="data.text !== 'Contratto insoluto'" [fixedWidth]="true" class="align-middle cursor-pointer" (click)="close()"></fa-icon>
    </div>
    <div id="overflow" class="overflow-y-auto slimscroll content tr-25">
      <ng-container *ngTemplateOutlet="data.template"></ng-container>
    </div>
  </div>
</div>