import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StoreService } from '../services/store/store.service';
import { AuthService } from '../services/auth/auth.service';
import { OverlayService } from '../services/overlay/overlay.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {

  private token: string;
  private companyId: string;

	constructor(private store: StoreService, private auth: AuthService, private overlay: OverlayService) {
    this.store.state$.pipe(map(s => ({ tkn: s.tkn, companyId: s.companyId }))).subscribe(s => {
      this.token = s.tkn;
      this.companyId = s.companyId;
    });
  }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request.clone({
      setHeaders: { 'Authorization': `${this.token}`, 'x-company-id': `${this.companyId}` }
    })).pipe(
      // catchError((error: HttpErrorResponse): Observable<any> => {
        // if (request.url.startsWith(environment.api.uri+environment.api.path) && (error.status === 400 || error.status === 404 || error.status === 500)) {
          // this.overlay.offLoading();
          // console.log(error.error);
          // this.overlay.showToast({ color: 'orange', icon: 'frown', text: error.error.error });
        // }
      //   if (request.url.startsWith(environment.api.uri+environment.api.path) && error.status === 406) {
      //     return throwError(error);
      //   }
      //   return of(error);
      // })
    );
	}
}
