import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from './services/overlay/overlay.service';
import { StoreService } from './services/store/store.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  title = 'smartbox-webapp';

  constructor(
    private overlay: OverlayService,
    private store: StoreService,
    private translateService: TranslateService
  ) {
    translateService.setDefaultLang('it');
    // translateService.addLangs(["en"]);

    // let browserLang: string = translateService.getBrowserLang();
    // translateService.use(browserLang.match(/it/) ? browserLang : translateService.getDefaultLang());
  }

  ngOnInit() {
    if (localStorage.getItem('sync')) {
      localStorage.setItem('refreshDate', new Date().toISOString());
      setTimeout(() => {
        this.store.storeSyncronizationInProgress('false');
        this.overlay.showToast({ text: 'Sincronizzazione asset management eseguita', icon: 'check', color: 'green' });
        localStorage.removeItem('sync');
        localStorage.removeItem('refreshDate');
      }, (300000 - (new Date(localStorage.getItem('refreshDate')).getTime() - new Date(localStorage.getItem('sync')).getTime())));
    }
  }
}
