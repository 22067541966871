import { OverlayRef } from "@angular/cdk/overlay";
import { TemplateRef } from "@angular/core";

export type ComponentState = "complete" | "loading" | "error";
export type AnimationState = "open" | "close";
export type AppTheme = "theme-light" | "theme-dark";
export type AppLayout = "mobile" | "desktop";
export type AppPagerBtn = number | "prev" | "next";

export type LazyElement = {
  id: string | number | object;
  text: string;
  subtext?: string;
};

export type AuthState =
  | "confirmSignIn"
  | "confirmSignUp"
  | "forgotPassword"
  | "requireNewPassword"
  | "signedIn"
  | "signIn"
  | "signUp";
export type UserRole =
  | "customer"
  | "administrators"
  | "service"
  | "manager"
  | "operator"
  | "salesman"
  | "EDOCustomer"
  | "truckFitter"
  | "diagnosticService";

export type associatedVehicle = { plateId: string; aciParameter: number };

//export type User = { id: number; username: string; firstName?: string; lastName?: string; lastAccess?: Date; email: string; role: UserRole; creatingMessage?: string; phone?: string; }
export type User = {
  userId: string;
  companyId: string;
  email: string;
  role: string;
  holdingId?: boolean;
  areas?: string;
  telephone?: string;
  mobilephone?: string;
  reports?: string[];
  companies?: string[];
  mfa?: boolean;
  associatedVehicles?: Array<associatedVehicle>;
  fullName?: string;
  date?: string;
  cf?: string;
  place?: string;
  terms?: boolean;
  extraEu?: boolean;
  marketing?: boolean;
};
export type Notification = {
  id: string;
  insertedAt: number;
  status: "read" | "unread";
  text: string;
  type: string;
  plateId: string;
  cdaTimestamp?: number;
};

export type UpdateResult = { code: string; status: number; message?: string };
export type CreateResult = { code: string; message?: string };
export type DeviceCounts = { totals?: number; activated?: number };
export type Events = { ok?: number; warn?: number; error?: number };
export type FirmwareVersion = { description?: string; count?: number };
export type FleetStatus = { status: string; value: number };
export type MaintenanceStatus = { status: string; value: number };
export type Position = { lat: number; lon: number; lastUpdate: string };
// Companies
export type Company = {
  companyId: string;
  name: string;
  address: string;
  businessName: string;
  city: string;
  zipCode: number;
  boxes: number;
  province: string;
  VATCode?: string;
  fiscalCode?: string;
  email?: string;
  PEC?: string;
  soEnabled?: string[];
  primaryColor?: string;
  contrastColor?: string;
  secondaryColor?: string;
  secondaryContrastColor?: string;
  tertiaryColor?: string;
  tertiaryContrastColor?: string;
  dbDataRetention?: number;
  s3DataRetention?: number;
  billingFrequency?: number;
  qtyRemoteDiagnosis?: number;
  dashboardUuid?: string;
  unresolved?: boolean;
  companies?: string[];
  connectionsDB?: string[];
  thirdPart?: boolean;
  truckFitterId?: string;
};
// Box
export type SmartBox = {
  id: string;
  plateId: string;
  certified: boolean;
  companyId: string;
  equipmentSerialNumber?: string;
  serialNumber?: string;
  preTestingDate?: string;
  deliveryDate?: string;
  testingAndCommissioningDate?: string;
  contractualTerm?: number;
  isTested?: boolean;
  rate?: number;
  chassisNumber?: string;
  dataType?: string;
  truckFitter?: string;
  commissioningNumber?: string;
  billingFrequency?: number;
};
export type SmartBoxDetail = {
  id: number;
  code?: String;
  customer?: string;
  lastReceivedEvent?: Date;
  state?: number;
  vehicle?: { code?: string; plate?: string; type?: string; driver?: Operator };
  version?: string;
};
export type SmartBoxList = { boxes: Array<SmartBox>; lastKey: SmartBox };
// Customers
export type Customer = {
  id: number;
  businessName?: string;
  smartboxes?: Array<SmartBox>;
  smartboxeCount?: number;
  users?: Array<User>;
  userCount?: number;
  address?: string;
  city?: string;
  province?: string;
  zipCode?: string;
};
export type CustomerMetadata = { userId: string; email: string };
export type CustomerArea = {
  name: string;
  coordinates: Array<{ lat: number; lon: number }>;
};
export type CustomerDetails = CustomerMetadata & {
  role: string;
  areas: Array<CustomerArea>;
};
// Vehicles
export type Vehicle = {
  plateId: string;
  status: number;
  type: string;
  company: string;
  companyCode: string;
  drivingStyle: 1 | 2 | 3 | 4;
  distance: number;
  speed: number;
  operatorId: string;
  position: Position;
  checkInOperator?: string;
};
export type VehiclesParams = {
  plateId?: string;
  operator?: string;
  area?: string;
  radius?: string;
  type?: string;
  company?: string;
  latA?: string;
  lonA?: string;
  latB?: string;
  lonB?: string;
  dashboard?: string;
  obsolete?: string;
  truckFitterId?: string;
};
export type VehicleInfo = {
  assetId: string;
  boxId: string;
  brand: string;
  companyDescription: string;
  companyId: string;
  description: string;
  engine: string;
  detention: "Affittato" | "Noleggiato" | "Automezzo di proprietà";
  kmPerYear: string;
  assetManager: boolean;
  hasPLC: boolean;
  leaving: string;
  legalDescription: string;
  massLimit: string;
  plateId: string;
  power: string;
  totalValueEquipmentRental: number;
  totalValueExternalServices: number;
  totalValueLabor: number;
  totalValueMaterials: number;
  totalValueTransfer: number;
  totalValueVariousDisposals: number;
  type: string;
  commandsEnabled?: any;
  area?: string;
  checkInOperator?: number;
  operatorId?: number; // Non c'è mai..?
  fms?: any;
  distance?: number;
  customOne?: string;
  customTwo?: string;
  customThree?: string;
  odometer?: number;
  totalHours?: number;
  declaredConsumption?: number;
  hoursConsumption?: number;
  maintenance?: number;
  equipmentMaintenance?: number;
  maintenanceHours?: number;
  eqMaintenanceHours?: number;
  fuelConsumptionCost?: number;
  totalFuelUsed?: number;
  isThermoKing?: boolean;
  importStatusFlag: number;
  isVisibleByCustomer?: boolean;
  co2Emissions?: number;
  truckFitter?: string;
  tankCapacity?: number;
  lastMaintenanceKm?: number;
  lastMaintenanceHours?: number;
  lastEqMaintenanceKm?: number;
  lastEqMaintenanceHours?: number;
  equipmentSerialNumber?: string;
  chassisNumber?: string;
  serialNumber?: string;
  businessName?: string;
  registrationDate?: string;
  isNotUnderContract?: boolean;
};
export type VehicleConsumption = {
  plateId: string;
  typeInsertedAt: string;
  timestamp: number;
  type: string;
  value: string;
  description: string;
};
export type VehicleLastKey = { pk: number; companyId: string; plateId: string };
export type VehiclesInfoParams = {
  plateId?: string;
  type?: string;
  companyId?: string;
  detention?: string;
  area?: string;
  loadAll?: string;
  lastKey?: string;
  truckFitterId?: string;
};
export type VehicleData = {
  plateId: string;
  companyId: string;
  companyDescription: string;
  legalDescription: string;
  power: string;
  kmPerYear?: number;
  brand: string;
  leaving: string;
  description: string;
  engine: string;
  massLimit: string;
  assetManager: boolean;
  hasPLC: boolean;
  type?: string;
  co2Emissions?: number;
  truckFitter?: string;
  tankCapacity?: number;
  area?: string;
  isVisibleByCustomer?: boolean;
  chassisNumber?: string;
  serialNumber?: string;
  equipmentSerialCode?: string;
};
export type VehicleInfoUpdate = {
  odometer?: number;
  maintenance?: number;
  equipmentMaintenance?: number;
  declaredConsumption?: number;
  totalHours?: number;
  hoursConsumption?: number;
  maintenanceHours?: number;
  eqMaintenanceHours?: number;
  customOne?: string;
  customTwo?: string;
  customThree?: string;
  co2Emissions?: number;
  lastMaintenanceKm?: number;
  lastMaintenanceHours?: number;
  lastEqMaintenanceKm?: number;
  lastEqMaintenanceHours?: number;
  type?: string;
  registrationDate?: string;
  isNotUnderContract?: boolean;
};
export type VehicleMaintenance = {
  initialDistance: number;
  maintenanceDistance: number;
};
export type VehiclesFilters = {
  types: string[];
  companies: Array<{ code: string; description: string }>;
  areas: Array<{ name: string; radius: number }>;
};
export type VehicleVideo = {
  key: string;
  status: string;
  uploadedAt: number;
  plateId?: string;
};
export type VehicleVideoParams = {
  plateId?: string;
  company?: string;
  area?: string;
  startDate?: string;
  endDate?: string;
};
export type VehicleStats = {
  companyId: string;
  date: string;
  distance: number;
  totalFuel: number;
  totalHours: number;
};
export type VehicleDailyStats = {
  plateId: string;
  date: string;
  pto: number;
  brush: number;
  emergency: number;
  stoppedAndTurnedOn: number;
  stoppedAndTurnedOff: number;
  inMotion: number;
  collision: number;
  ptoTotalHour?: number;
};
// Operators
export type OperatorMetadata = { operatorId: string };
export type OperatorLicense = {
  type: string;
  expirationDate: string;
  number: string;
};
export type OperatorData = {
  badge?: string;
  name: string;
  surname: string;
  name_surname: string;
  company: string;
  qualification?: string;
  belongingArea?: string;
  email?: string;
  mobilePhone?: string;
  phone?: string;
  license?: OperatorLicense;
  customOne?: string;
  customTwo?: string;
};
export type Operator = OperatorMetadata & OperatorData;
export type WorkShifts = {
  plateId: string;
  startDate: number;
  operatorId: string;
  operatorIdPlateId: string;
  endDate: string;
  drivingStyle?: number;
};
export type WorkShiftsParams = {
  operatorId?: string;
  plateId?: string;
  startDate?: string;
  endDate?: string;
  lastOperatorId?: string;
  lastPlateId?: string;
  lastOperatorIdPlateId?: string;
  lastStartDate?: string;
};
export type WorkShiftsKey = {
  operatorId: string;
  operatorIdPlateId: string;
  plateId: string;
  startDate: string;
};
export type WorkShiftsStats = {
  operatorId: string;
  date: string;
  drivingStyle: number;
  duration: number;
  distance: number;
};
// Paths
export type OptimalPath = {
  name: string;
  plateId: string;
  from: number;
  to: number;
};
export type PathDataset = {
  pto: 0 | 1;
  brush: 0 | 1;
  emergency: 0 | 1;
  stoppedAndTurnedOn: 0 | 1;
  stoppedAndTurnedOff: 0 | 1;
  inMotion: 0 | 1;
  collision: 0 | 1;
  discharge?: 0 | 1;
  compaction?: 0 | 1;
  avc?: 0 | 1;
};
export type PathDetails = {
  pto: number;
  emergency: number;
  collision: number;
  brush: number;
  ptoActiveTime: number;
  brushActiveTime: number;
  soffTime: number;
  sonTime: number;
  motTime: number;
  sonPtoTime: number;
  distance?: number;
  fuelUsed?: number;
  averageFuelUsed?: number;
  discharge?: number;
  compaction?: number;
  avc?: number;
  privacyTime?: number;
};
export type Path = {
  positions: Array<PathPosition>;
  pathDetails: PathDetails;
  lastKey: { hashKey: string; rangeKey: string; plateId: string };
  thirdPart: boolean;
};
export type PathPosition = {
  speed: number;
  totalFuelUsed: number;
  plateId: string;
  drivingStyle: 1 | 2 | 3 | 4;
  datasets: PathDataset;
  hardTurn: number;
  hardBrake: number;
  hardAcceleration: number;
} & Position;
export type PathList = Array<{
  startDate: number;
  endDate: number;
  date: string;
  plateId: string;
  operatorId: string;
  operatorIdPlateId: string;
}>;
export type PathsListParams = {
  startDate: string;
  endDate: string;
  lat?: string;
  lon?: string;
  radus?: string;
  plateId?: string;
  operatorId?: string;
};
export type PathCoordinates = {
  lat?: string;
  lon?: string;
};
export type PathParams = {
  plateId: string;
  from: string;
  to: string;
  hashKey?: string;
  rangeKey?: string;
  asset?: string;
  operator?: string;
  pto?: string;
  emergency?: string;
  collision?: string;
  brush?: string;
  multiDate?: string;
  discharge?: string;
  compaction?: string;
  avc?: string;
};
export type PathExport = {
  images: { map: string; speed: string; iot: string; dataset: string };
  filters: {
    plateId: string;
    from: string;
    to: string;
    asset?: string;
    operator?: string;
  };
  pathDetails: any;
  coordinates: { latA: string; lonA: string; latB: string; lonB: string };
};
export type PathExportKML = {
  points: Array<PathCoordinates>;
};
export type PathsExport = {
  paths: string;
  filters: {
    operator: string;
    address: string;
    plateId: string;
    period: string;
  };
};
export type ExportIotData = {
  plateId: string;
  from: string;
  to: string;
};
// Poi
export type PoiType =
  | "ABITAZIONE"
  | "PERCORSO"
  | "EDIFICIO"
  | "UFFICIO"
  | "MAGAZZINO"
  | "PARCHEGGIO"
  | "AUTORIMESSA"
  | "PIAZZALE"
  | "PARCO";
export type Poi = {
  name: string;
  formatted_name?: string;
  type: PoiType;
  position: { lat: number; lon: number };
  route: { id: number; position: Position };
  lat: number;
  lon: number;
};
export type PoiRoute = {
  name: string;
  formatted_name?: string;
  routeId: number;
};
export type PoiRouteParams = { name?: string; routeId?: string };
export type PoiParams = {
  name?: string;
  type?: PoiType;
  radius?: string;
  lat?: string;
  lon?: string;
  latA?: string;
  lonA?: string;
  latB?: string;
  lonB?: string;
};
export type PoiDetails = {
  hashKey: number;
  name: string;
  type: PoiType;
  position: { lat: number; lon: number };
  route: { id: number; position: number };
};
// Map
export type MapExport = {
  images: { map: string };
  filters: {
    plateId?: string;
    operator?: string;
    area?: string;
    company?: string;
    type?: string;
  };
};
// Audits
export type Audits = {
  caller: string;
  httpMethod: string;
  ip: string;
  path: string;
  pathParameters: string;
  queryString: string;
  status: string;
  timestamp: number;
  userId: string;
  userIdPath: string;
};
export type AuditsParams = {
  userId: string;
  path: string;
  from: string;
  to: string;
  lastUserId: string;
  lastUserIdPath: string;
  lastPath: string;
  lastTimestamp: string;
};
export type AuditsLastKey = {
  userId: string;
  timestamp: number;
  userIdPath: string;
  caller: string;
  path: string;
};
export type AuditsExport = {
  audits: string;
  filters: { from?: number; to?: number; userId?: string; path?: string };
};
// Logs
export type Logs = {
  boxId: string;
  timestamp: number;
  item: string;
  eventType: string;
  boxIdEventType: string;
};
export type LogsParams = {
  boxId: string;
  eventType: string;
  from: string;
  to: string;
  lastBoxId: string;
  lastBoxIdEventType: string;
  lastEventType: string;
  lastTimestamp: string;
};
export type LogsLastKey = {
  boxId: string;
  timestamp: number;
  boxIdEventType: string;
  item: string;
  eventType: string;
};
export type LogsExport = {
  logs: string;
  filters: { from?: number; to?: number; boxId?: string; eventType?: string };
};
// Kpi
export type KpiFilters = {
  plateId?: string;
  operatorId?: string;
  company?: string;
  type?: string;
  year?: string;
  quarter?: string;
  semester?: string;
  month?: string;
  timeType?: "month" | "quarter" | "semester" | "year";
};
export type KpiList = {
  kpi15628: { attr: string; label: string; set: any[] }[];
  kpi39001: { attr: string; label: string; set: any[] }[];
};
export class KpiObject {
  kpi15628?: {
    AvgTrip: number;
    FMSAnomaly: number;
    HConsumption: number;
    KMConsumption: number;
    Maintenance: number;
    PLCAnomaly: number;
    HPTO: number;
    KMPTO: number;
  };
  kpi39001?: {
    AvgDriverSpeed: number;
    AvgKmSpeed: number;
    HConsumption: number;
    KMConsumption: number;
  };
}
export class Kpi {
  5628?: {
    AvgTrip: number;
    FMSAnomaly: number;
    HConsumption: number;
    KMConsumption: number;
    Maintenance: number;
    PLCAnomaly: number;
    HPTO: number;
    KMPTO: number;
  };
  39001?: {
    AvgDriverSpeed: number;
    AvgKmSpeed: number;
    HConsumption: number;
    KMConsumption: number;
  };
  Interval?: string;
  company?: string;
  Id?: string;
  type?: string;
}
export class KpiResponse implements Kpi, KpiObject, Error {
  Id? = null;
  Interval? = null;
  name = null;
  message = null;
  notDefinedDiff? = null;
  kpi15628? = {
    AvgTrip: 0,
    FMSAnomaly: 0,
    HConsumption: 0,
    KMConsumption: 0,
    Maintenance: 0,
    PLCAnomaly: 0,
    HPTO: 0,
    KMPTO: 0,
  };
  kpi39001? = {
    AvgDriverSpeed: 0,
    AvgKmSpeed: 0,
    HConsumption: 0,
    KMConsumption: 0,
  };
}
// Diagnostics
export type Diagnostics = {
  brand?: string;
  model?: string;
  plateId: string;
  insertedAt: string; // "1590051133";
  DC_high_resolution_DC_engine_total_fuel_used: number;
  DC_accelerator_pedal_position: 0;
  DC_ambient_air_temperature: number; //: 0
  DC_at_least_one_pto_engaged: number; //: 0
  DC_clutch_switch: number; //: 1
  DC_engine_coolant_temperature: number; //: 87
  DC_engine_percent_load_at_current_speed: number; //: 39
  DC_engine_speed: number; //: 601
  DC_engine_total_fuel_used: number; //: 118458
  DC_engine_total_hours_of_operation: number; //: 16771.45
  DC_fuel_level: number; //: 82
  DC_fuel_rate: number; //: 3.9000000000000004
  DC_high_resolution_engine_total_fuel_used: number; //: 0
  DC_high_resolution_total_vehicle_distance: number; //: 183856010
  DC_instantaneous_fuel_economy: number; //: 0
  DC_pto_state: number; //: 0
  DC_service_distance: number; //: 167040
  DC_tachograph_vehicle_speed: number; //: 0
  DC_wheel_based_speed: number; //: 0
  DP_EMERGENCY: number; //: 0
  DP_PLATFORM_DX: number; //: 0
  DP_PLATFORM_SX: number; //: 0
  DP_PTO: number; //: 0
  DP_VTC_A: number; //: 0
  DP_VTC_B: number; //: 1
  DP_DRIVER_FLAG: number; //: 1
  DP_EDO_ACTIVE: number; //: 1
  DP_EDO_EMERGENCY: number; //: 1
  DP_EDO_ENABLED: number; //: 1
  DP_HOUR_ENG_ON_NOSPD: number; //: 789
  DP_KM_TRIP: number; //: 30.00
  DP_PTO_HOUR_COUNTER: number; //: 456
  DP_PTO_INSERTION_COUNTER: number; //: 123
  cda: Array<{ name: string; level: string }>;
  imu: Array<{
    x: number;
    scale: number;
    y: number;
    z: number;
    timestamp: string;
  }>; // {x: -2620, scale: 0.000598568, y: 1057, z: 15570, timestamp: "2020-05-21T08:51:50.415Z"}
  masterkey: Array<{ name: string; value: number }>; // {name: "masterkey", value: 1}
  privacy: Array<{ name: string; value: number }>; // {name: "privacy", value: 0}
  timestamp: string; // "2020-05-21T08:52:14.499Z"
  uid: Array<any>;
  iot?: { [key: string]: Array<{ name: string; [key: string]: string }> };
};
export type Telemetry = {
  plateId: string;
  insertedAt: string;
  diagnostics: TelemetryData;
};
export type TelemetryData = {
  box_type?: string; // "smartbox50",
  camera_connected?: boolean;
  camera_connection_count?: number; // 181,
  camera_service_started?: boolean;
  clock_service_started?: boolean;
  clock_sync?: boolean;
  clock_sync_offset?: number; // 12160450609,
  clock_sync_source?: "ntp" | "gps";
  gateway_service_started?: boolean;
  gps_hw?: boolean;
  gps_jamming_level?: number; // da 0 a 100
  gps_jamming_state?: number; // da 0 a 5
  gps_service_started?: boolean;
  imu_hw?: boolean;
  imu_service_started?: boolean;
  om_service_started?: boolean;
  rfid_hw?: boolean;
  rfid_service_started?: boolean;
  timestamp?: string; // "2020-09-30T07:43:13.766Z",
  version_blackbox?: string; // "1.10.0-alpha.3",
  version_bsp?: string; // "MGH 1.9.2 (MGH)"
};

export type RemoteDiagnostics = {
  brand?: string;
  model?: string;
  plateId: string;
  insertedAt: string;
  cda: Array<{ name: string; level: string }>;
  imu: Array<{
    x: number;
    scale: number;
    y: number;
    z: number;
    timestamp: string;
  }>; // {x: -2620, scale: 0.000598568, y: 1057, z: 15570, timestamp: "2020-05-21T08:51:50.415Z"}
  masterkey: Array<{ name: string; value: number }>; // {name: "masterkey", value: 1}
  privacy: Array<{ name: string; value: number }>; // {name: "privacy", value: 0}
  timestamp: string; // "2020-05-21T08:52:14.499Z"
  uid: Array<any>;
};

// IoT
export type IotData = {
  [key: string]: { value: number; label: string; insertedAt: string }[];
};

// Anomalies
export type AppAnomaly = { plateId: string; insertedAt: string; event: any };
export type AppAnomalyResponse = {
  PLCAnomalies?: AppAnomaly[];
  FMSAnomalies?: AppAnomaly[];
  lastKey?: { plateId: string; insertedAt: string };
};
export type AppAnomalyCounter = {
  plateId: string;
  errors: number;
  lastUpdate: string;
  urgent: boolean;
  cam?: number;
  gps?: number;
  imu?: number;
  rfid?: number;
  boxId?: string;
  box_type?: string;
  version_blackbox?: string;
  version_bsp?: string;
  gpsLastUpdate?: string;
  imuLastUpdate?: string;
  camLastUpdate?: string;
  errorsLastUpdate?: string;
  rfidLastUpdate?: string;
  serviceExpired?: number;
  serviceExpiredLastUpdate?: string;
};

// Commands

export type Commands = {
  boxId: string;
  plateId: string;
  cmd_name: string;
  cmd_value: any;
  cmd_error: string;
  createdAt?: string;
  operatorId?: string;
};

export type ServiceOrder = {
  areas: [
    {
      areaName: string;
      options: [
        {
          command: string;
          trigger: string;
          values: [
            {
              value: string | number;
              zone?: string;
            }
          ];
        }
      ];
    }
  ];
  companyId: string;
  createdAt: number;
  endDate: number;
  plateId: string[];
  startDate: number;
  type: string;
  updatedAt: number;
  name: string;
  title?: string;
  color?: string;
  start?: string;
  end?: string;
};

export type ServiceOrderAreas = {
  companyId: string;
  name: string;
  coordinates: [
    {
      lat: number;
      lon: number;
    }
  ];
  isModel: boolean;
};

export type ServiceOrderStats = {
  plateId: string;
  day_position: string;
  companyId: string;
  ods_createdAt: number;
  positionStatus: number;
  createdAt: Date;
  updatedAt: Date;
  areaTime: number;
  stoppedAndTurnedOn: number;
  stoppedAndTurnedOff: number;
  inMotion: number;
  distance: number;
  fuelUsed: number;
  totalFuelLevel: number;
  lastPosition: string;
  numSentCommands: number;
  entryTime: number;
  exitTime: number;
  commandsExecuted?: [
    {
      createdAt: number;
      plateId: string;
      cmd: string;
      error: boolean;
      value: number;
      origin: string;
    }
  ];
};

// kilometric refund
export type JourneyDetails = {
  plateId: string;
  date: string;
  date_index: string;
  endDate: number;
  startDate: number;
  distance: number;
  startPoint: string;
  endPoint: string;
  parking?: number;
  highwayTolls?: number;
  travelExpenses?: number;
  food?: number;
  lodging?: number;
  description?: string;
  otherExpenses?: number;
  excluded?: boolean;
};

// edo devices
export type EdoDevice = {
  boxId: string;
  plateId: string;
  chassisNumber: string;
  brand: string;
  model: string;
  consumptionEDO?: number;
  consumptionNoEdo?: number;
  SOS?: boolean;
};

// remote sessions
export type RemoteSession = {
  plateId: string;
  startTime: number;
  endTime: number;
};

export class OverlayData {
  icon?: string;
  text?: string;
  color?: "primary" | "green" | "orange" | "red";
  template?: TemplateRef<any>;
  width?: number | string;
  timeout?: number | "infinite";
}
export class OverlayComponentRef {
  constructor(private readonly overlay: OverlayRef) {}
  public backdropClick = this.overlay.backdropClick();
  public close() {
    this.overlay.dispose();
  }
  public isVisible() {
    return this.overlay && this.overlay.overlayElement;
  }
  public getPosition() {
    return this.overlay.overlayElement.getBoundingClientRect();
  }
}
