import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { takeWhile } from "rxjs/operators";
import { entryOutlet } from "src/app/animations/entry";
import { PATHS } from "src/app/app.routes";
import { ApiService } from "src/app/services/api/api.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { OverlayService } from "src/app/services/overlay/overlay.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { StoreService } from "src/app/services/store/store.service";
import {
  AppLayout,
  AppTheme,
  OverlayComponentRef,
  User,
  UserRole,
} from "src/app/utils/types";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
  animations: [entryOutlet],
})
export class MainComponent implements OnInit {
  @ViewChild("termsAndConditionsModal", { static: true })
  termsAndConditionsModal: TemplateRef<any>;
  @ViewChild("unresolvedModal", { static: true })
  unresolvedModal: TemplateRef<any>;

  public user: string;
  public role: UserRole = null;
  public companyId: string = null;
  public email: string = null;
  public logo: string = "/assets/img/company_mgh.png";
  public wheeliot_logo: string = "/assets/img/logo_wheeliot.png";
  public companies: { companyId: string; name: string }[] = [];
  public layout: AppLayout;
  public paths = PATHS;
  public theme: AppTheme;
  public toggle: "toggle-on" | "toggle-off";
  public menus = [];
  public hasNotif = false;
  public userDetails: User;
  public acceptedTerms: {
    fullName: string;
    date: string;
    cf: string;
    place: string;
    terms: string;
    extraEu: string;
    marketing: string;
  };
  public unresolved = false;
  public truckFitterId: string = null;

  private modal: OverlayComponentRef;

  constructor(
    private store: StoreService,
    private storage: StorageService,
    private overlay: OverlayService,
    private api: ApiService,
    private auth: AuthService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.store.state$
      .pipe(takeWhile((x) => x.uid === null || x.companyId === null, true))
      .subscribe((state) => {
        this.companyId = state.companyId;
        this.layout = state.layout;
        this.user = state.uid ? (state.uid as string) : ""; // TODO
        this.toggle = state.theme === "theme-dark" ? "toggle-on" : "toggle-off";
        this.email = state.email;
        this.checkTheme(this.companyId);
        this.api.getMe(this.user).subscribe(
          (u) => {
            this.userDetails = u;
            if (u.role === "truckFitter") {
              this.truckFitterId = u.companyId;
              this.store.storeTruckFitter(this.truckFitterId);
            }
            this.checkTermsAndConditions();
          },
          (error: Error) => {
            this.overlay.offLoading();
          }
        );
        if (this.role !== state.role) {
          this.api.getCompanies().subscribe((c) => {
            this.companies = c.companies
              .map(({ companyId, name }) => ({ companyId, name }))
              .sort((a, b) =>
                a.companyId > b.companyId
                  ? 1
                  : b.companyId > a.companyId
                  ? -1
                  : 0
              );
            if (!this.companyId) {
              this.companyId = this.companies[0].companyId;
              this.store.storeCompany(this.companyId);
            }
            this.api.getCompany(this.companyId).subscribe((r) => {
              this.unresolved = r.company.unresolved || false;
              this.checkUnresolved();
            });
            this.checkLogo();
            this.checkWheeliotLogo();
            this.api.getMe(this.user).subscribe((me) => {
              if (state.role === "truckFitter") {
                this.companies = this.companies.filter(
                  (c) => c.companyId !== me.companyId
                );
              }
              if (!this.companies.find((c) => c.companyId === this.companyId)) {
                this.companyId = this.companies[0].companyId;
                this.changeCompany(this.companyId);
              }
            });
          });
          this.role = state.role;
        } else {
          this.api.getCompany(this.companyId).subscribe((r) => {
            this.unresolved = r.company.unresolved || false;
            this.checkUnresolved();
          });
        }
        this.api
          .getNotifications({ latestOnly: "1" })
          .subscribe(
            (r) =>
              (this.hasNotif =
                r.notifications &&
                r.notifications.find((n) => n.status === "unread")
                  ? true
                  : false)
          );
      });

    this.translateService.get("menu").subscribe((res) => {
      switch (this.role) {
        case "customer": {
          this.menus = [
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
          ];
          break;
        }
        case "manager": {
          if (this.companyId === "TKS") {
            this.menus = [
              { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
              { icon: "box", path: PATHS.box, label: "Box" },
              // { icon: 'tachometer-alt', path: PATHS.kpi, label: 'KPI' },
              { icon: "map-marked-alt", path: PATHS.map, label: res.map },
              { icon: "road", path: PATHS.path, label: res.paths },
              {
                icon: "route",
                path: PATHS.pathDetails,
                label: res["path-details"],
              },
              { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
              { icon: "users", path: PATHS.drivers, label: res.drivers },
              { icon: "drafting-compass", path: PATHS.areas, label: res.areas },
              { icon: "map-pin", path: PATHS.poi, label: "POI" },
              { icon: "video", path: PATHS.video, label: res.videos },
              {
                icon: "chart-line",
                path: PATHS.telemetries,
                label: res.telemetries,
              },
              {
                icon: "bell",
                path: PATHS.notifications,
                label: res.notifications,
              },
              { icon: "terminal", path: PATHS.commands, label: res.commands },
              {
                icon: "box-open",
                path: PATHS.serviceOrders,
                label: res["service-orders"],
              },
              { icon: "address-book", path: PATHS.users, label: res.users },
              { icon: "flag", path: PATHS.reports, label: "Reports" },
              {
                icon: "calendar",
                path: PATHS.telemetriesList,
                label: "Elenco telemetrie",
              },
              // Da aggiungere utenti e audit dopo riduzione a scope di competenza
            ];
            break;
          } else {
            this.menus = [
              { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
              { icon: "box", path: PATHS.box, label: "Box" },
              // { icon: 'tachometer-alt', path: PATHS.kpi, label: 'KPI' },
              { icon: "map-marked-alt", path: PATHS.map, label: res.map },
              { icon: "road", path: PATHS.path, label: res.paths },
              {
                icon: "route",
                path: PATHS.pathDetails,
                label: res["path-details"],
              },
              { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
              { icon: "users", path: PATHS.drivers, label: res.drivers },
              { icon: "drafting-compass", path: PATHS.areas, label: res.areas },
              { icon: "map-pin", path: PATHS.poi, label: "POI" },
              { icon: "video", path: PATHS.video, label: res.videos },
              {
                icon: "chart-line",
                path: PATHS.telemetries,
                label: res.telemetries,
              },
              {
                icon: "bell",
                path: PATHS.notifications,
                label: res.notifications,
              },
              { icon: "terminal", path: PATHS.commands, label: res.commands },
              {
                icon: "box-open",
                path: PATHS.serviceOrders,
                label: res["service-orders"],
              },
              { icon: "flag", path: PATHS.reports, label: "Reports" },
              {
                icon: "calendar",
                path: PATHS.telemetriesList,
                label: "Elenco telemetrie",
              },
              // Da aggiungere utenti e audit dopo riduzione a scope di competenza
            ];
            if (this.companyId === "VITALI") {
              this.menus.splice(1, 0, {
                icon: "chart-bar",
                path: PATHS.productionDashboard,
                label: "Produzione",
              });
            }
            break;
          }
        }
        case "salesman": {
          this.menus = [
            { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
            { icon: "box", path: PATHS.box, label: "Box" },
            // { icon: 'tachometer-alt', path: PATHS.kpi, label: 'KPI' },
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
            { icon: "road", path: PATHS.path, label: res.paths },
            {
              icon: "route",
              path: PATHS.pathDetails,
              label: res["path-details"],
            },
            { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
            { icon: "users", path: PATHS.drivers, label: res.drivers },
            { icon: "drafting-compass", path: PATHS.areas, label: res.areas },
            { icon: "map-pin", path: PATHS.poi, label: "POI" },
            { icon: "video", path: PATHS.video, label: res.videos },
            {
              icon: "chart-line",
              path: PATHS.telemetries,
              label: res.telemetries,
            },
            {
              icon: "bell",
              path: PATHS.notifications,
              label: res.notifications,
            },
            { icon: "terminal", path: PATHS.commands, label: res.commands },
            {
              icon: "box-open",
              path: PATHS.serviceOrders,
              label: res["service-orders"],
            },
            { icon: "flag", path: PATHS.reports, label: "Reports" },
            {
              icon: "toolbox",
              path: PATHS.administration,
              label: "Amministrazione",
            },
            // Da aggiungere utenti e audit dopo riduzione a scope di competenza
          ];
          break;
        }
        case "EDOCustomer": {
          this.menus = [
            { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
            {
              icon: "microchip",
              path: PATHS.edoDevices,
              label: "Dispositivi EDO",
            },
            { icon: "box", path: PATHS.box, label: "Box" },
            // { icon: 'tachometer-alt', path: PATHS.kpi, label: 'KPI' },
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
            {
              icon: "route",
              path: PATHS.pathDetails,
              label: res["path-details"],
            },
            { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
            { icon: "map-pin", path: PATHS.poi, label: "POI" },
            {
              icon: "chart-line",
              path: PATHS.telemetries,
              label: res.telemetries,
            },
            { icon: "flag", path: PATHS.reports, label: "Reports" },
          ];
          break;
        }
        case "service": {
          this.menus = [
            { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
            { icon: "road", path: PATHS.path, label: res.paths },
            {
              icon: "route",
              path: PATHS.pathDetails,
              label: res["path-details"],
            },
            { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
            { icon: "users", path: PATHS.drivers, label: res.drivers },
            { icon: "map-pin", path: PATHS.poi, label: "POI" },
            { icon: "video", path: PATHS.video, label: res.videos },
            {
              icon: "chart-line",
              path: PATHS.telemetries,
              label: res.telemetries,
            },
            {
              icon: "bell",
              path: PATHS.notifications,
              label: res.notifications,
            },
          ];
          break;
        }
        case "diagnosticService": {
          this.menus = [
            { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
            { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
            {
              icon: "chart-line",
              path: PATHS.telemetries,
              label: res.telemetries,
            },
            { icon: "terminal", path: PATHS.commands, label: res.commands },
            {
              icon: "calendar",
              path: PATHS.telemetriesList,
              label: "Elenco telemetrie",
            },
            {
              icon: "satellite-dish",
              path: PATHS.remoteSessionsList,
              label: "Elenco sessioni remote",
            },
          ];
          break;
        }
        case "operator": {
          this.menus = [
            { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
            { icon: "road", path: PATHS.path, label: res.paths },
            {
              icon: "route",
              path: PATHS.pathDetails,
              label: res["path-details"],
            },
            { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
            { icon: "users", path: PATHS.drivers, label: res.drivers },
            { icon: "map-pin", path: PATHS.poi, label: "POI" },
            { icon: "video", path: PATHS.video, label: res.videos },
            {
              icon: "chart-line",
              path: PATHS.telemetries,
              label: res.telemetries,
            },
          ];
          break;
        }
        case "truckFitter": {
          this.menus = [
            { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
            { icon: "box", path: PATHS.box, label: "Box" },
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
            {
              icon: "route",
              path: PATHS.pathDetails,
              label: res["path-details"],
            },
            { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
            {
              icon: "chart-line",
              path: PATHS.telemetries,
              label: res.telemetries,
            },
            { icon: "flag", path: PATHS.reports, label: "Reports" },
            {
              icon: "calendar",
              path: PATHS.telemetriesList,
              label: "Elenco telemetrie",
            },
            {
              icon: "satellite-dish",
              path: PATHS.remoteSessionsList,
              label: "Elenco sessioni remote",
            },
          ];
          break;
        }
        case "administrators": {
          this.menus = [
            { icon: "home", path: PATHS.dashboard, label: "Dashboard" },
            {
              icon: "microchip",
              path: PATHS.edoDevices,
              label: "Dispositivi EDO",
            },
            { icon: "building", path: PATHS.customers, label: res.customers },
            { icon: "box", path: PATHS.box, label: "Box" },
            { icon: "tachometer-alt", path: PATHS.kpi, label: "KPI" },
            { icon: "map-marked-alt", path: PATHS.map, label: res.map },
            { icon: "road", path: PATHS.path, label: res.paths },
            {
              icon: "route",
              path: PATHS.pathDetails,
              label: res["path-details"],
            },
            { icon: "truck", path: PATHS.vehicles, label: res.vehicles },
            { icon: "users", path: PATHS.drivers, label: res.drivers },
            { icon: "drafting-compass", path: PATHS.areas, label: res.areas },
            { icon: "map-pin", path: PATHS.poi, label: "POI" },
            { icon: "video", path: PATHS.video, label: res.videos },
            {
              icon: "chart-line",
              path: PATHS.telemetries,
              label: res.telemetries,
            },
            {
              icon: "chart-area",
              path: PATHS.diagnostics,
              label: res.diagnostics,
            },
            { icon: "address-book", path: PATHS.users, label: res.users },
            { icon: "receipt", path: PATHS.audit, label: "Audit" },
            { icon: "list", path: PATHS.log, label: "Log" },
            { icon: "bug", path: PATHS.anomalies, label: res.anomalies },
            {
              icon: "bell",
              path: PATHS.notifications,
              label: res.notifications,
            },
            { icon: "terminal", path: PATHS.commands, label: res.commands },
            {
              icon: "box-open",
              path: PATHS.serviceOrders,
              label: res["service-orders"],
            },
            { icon: "flag", path: PATHS.reports, label: "Reports" },
            // { icon: 'route', path: PATHS.routePlanning, label: 'Pianificazione percorsi' }
            // { icon: 'share-alt', path: PATHS.carSharing, label: 'Car sharing' }
            {
              icon: "car",
              path: PATHS.kilometricRefund,
              label: "Rimborso chilometrico",
            },
            {
              icon: "toolbox",
              path: PATHS.administration,
              label: "Amministrazione",
            },
            {
              icon: "database",
              path: PATHS.dbAdministration,
              label: "Amministrazione DB",
            },
            {
              icon: "calendar",
              path: PATHS.telemetriesList,
              label: "Elenco telemetrie",
            },
            {
              icon: "satellite-dish",
              path: PATHS.remoteSessionsList,
              label: "Elenco sessioni remote",
            },
          ];
          if (this.companyId === "VITALI") {
            this.menus.splice(1, 0, {
              icon: "chart-bar",
              path: PATHS.productionDashboard,
              label: "Produzione",
            });
          }
          if (this.companyId === "RIBOLDI") {
            this.menus.splice(1, 0, {
              icon: "chart-pie",
              path: PATHS.quicksight,
              label: "Analisi",
            });
          }
          // if (this.layout !== 'desktop') {
          //   this.menus.push(
          //     { icon: '', path: '', label: '' },
          //     { icon: '', path: '', label: '' }
          //   );
          // }
          break;
        }
      }
    });
  }

  public async checkLogo() {
    let checkLogoLink = (
      await fetch(
        "https://" +
          environment.amplify.Storage.AWSS3.bucket +
          ".s3." +
          environment.amplify.Storage.AWSS3.region +
          `.amazonaws.com/${encodeURIComponent(
            this.companyId
          )}/${encodeURIComponent(this.store.getEmail())}/logo.jpg`
      )
    ).status;
    this.logo =
      checkLogoLink == 404
        ? "/assets/img/company_mgh.png"
        : "https://" +
          environment.amplify.Storage.AWSS3.bucket +
          ".s3." +
          environment.amplify.Storage.AWSS3.region +
          `.amazonaws.com/${encodeURIComponent(
            this.companyId
          )}/${encodeURIComponent(this.store.getEmail())}/logo.jpg`;
  }

  public async checkWheeliotLogo() {
    let checkLogoLink = (
      await fetch(
        "https://" +
          environment.amplify.Storage.AWSS3.bucket +
          ".s3." +
          environment.amplify.Storage.AWSS3.region +
          `.amazonaws.com/${encodeURIComponent(
            this.companyId
          )}/wheeliot_logo.jpg`
      )
    ).status;
    this.wheeliot_logo =
      checkLogoLink !== 200
        ? "/assets/img/logo_wheeliot.png"
        : "https://" +
          environment.amplify.Storage.AWSS3.bucket +
          ".s3." +
          environment.amplify.Storage.AWSS3.region +
          `.amazonaws.com/${encodeURIComponent(
            this.companyId
          )}/wheeliot_logo.jpg`;
  }

  public async checkTheme(company: string) {
    this.api.getCompany(company).subscribe((res) => {
      if (res.company.primaryColor) {
        document.documentElement.style.setProperty(
          "--c-1",
          res.company.primaryColor
        );
      } else {
        document.documentElement.style.setProperty("--c-1", "#54BDBA");
      }
      if (res.company.primaryContrastColor) {
        document.documentElement.style.setProperty(
          "--c-1c",
          res.company.primaryContrastColor
        );
      } else {
        document.documentElement.style.setProperty("--c-1c", "#ffffff");
      }
      if (res.company.secondaryColor) {
        document.documentElement.style.setProperty(
          "--c-2",
          res.company.secondaryColor
        );
      } else {
        document.documentElement.style.setProperty("--c-2", "#FFDE05");
      }
      if (res.company.secondaryContrastColor) {
        document.documentElement.style.setProperty(
          "--c-2c",
          res.company.secondaryContrastColor
        );
      } else {
        document.documentElement.style.setProperty("--c-2c", "#1C1C1C");
      }
      if (res.company.tertiaryColor) {
        document.documentElement.style.setProperty(
          "--c-3",
          res.company.tertiaryColor
        );
      } else {
        document.documentElement.style.setProperty("--c-3", "#F28F1C");
      }
      if (res.company.tertiaryContrastColor) {
        document.documentElement.style.setProperty(
          "--c-3c",
          res.company.tertiaryContrastColor
        );
      } else {
        document.documentElement.style.setProperty("--c-3c", "#FFFFFF");
      }
    });
  }

  toggleTheme() {
    this.store.toggleTheme();
  }

  toggleSidebar(template: TemplateRef<any>) {
    this.overlay.showSidebar({ template });
  }

  openUserbox(parent: HTMLElement, template: TemplateRef<any>) {
    this.modal =
      this.layout === "desktop"
        ? this.overlay.showContextMenu(parent, {
            icon: "user",
            text: this.user,
            template,
          })
        : this.overlay.showModal({ icon: "user", template });
  }

  openNotibox(parent: HTMLElement, template: TemplateRef<any>) {
    this.modal =
      this.layout === "desktop"
        ? this.overlay.showContextMenu(parent, {
            icon: "bell",
            text: "Notifiche",
            template,
            width: 300,
          })
        : this.overlay.showModal({ icon: "bell", text: "Notifiche", template });
  }

  async signOut() {
    try {
      this.store.removeUID();
      localStorage.removeItem("u-wheeliot");
      const o = await this.auth.signOut(true);
      this.modal.close();
      this.router.navigate(["/login"]);
    } catch {}
  }

  changeCompany(company: string) {
    this.store.storeCompany(company);
    this.checkTheme(company);
    this.checkLogo();
    this.checkWheeliotLogo();
    this.checkTermsAndConditions();
  }

  checkTermsAndConditions() {
    this.api.getMe(this.user).subscribe((u) => {
      this.userDetails = u;
      if (u.role === "truckFitter") {
        this.truckFitterId = u.companyId;
        this.store.storeTruckFitter(this.truckFitterId);
      }
      if (!this.userDetails.terms) {
        this.openModal(
          this.termsAndConditionsModal,
          "Termini e condizioni",
          "check"
        );
      }
    });
  }

  checkUnresolved() {
    if (this.unresolved && this.role !== "administrators") {
      this.openModal(this.unresolvedModal, "Contratto insoluto", "times");
    }
  }

  getPdf() {
    this.storage
      .get("Wheeliot_Manuale_Applicativo.pdf")
      .then((l) =>
        this.router.navigate([]).then(() => window.open(l, "_blank"))
      );
  }

  public openModal(template: TemplateRef<any>, text: string, icon: string) {
    this.modal = this.overlay.showModal({ template, text, icon });
  }
}
