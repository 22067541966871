import { Component, OnInit } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { fadeLeft } from 'src/app/animations/fade';
import { AnimationState, OverlayData, OverlayComponentRef } from 'src/app/utils/types';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [fadeLeft]
})
export class SidebarComponent implements OnInit {

  public animationState: AnimationState;

  constructor(readonly data: OverlayData, readonly ref: OverlayComponentRef) { }

  ngOnInit() {
    this.animationState = 'open';
  }

  close() {
    this.animationState = 'close';
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    if (toState === 'close' && this.animationState === 'close') {
      this.ref.close();
    } else {
      this.ref.backdropClick.subscribe(_ => this.close());
    }
  }
}
