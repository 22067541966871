import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppLayout, AppTheme, UserRole } from 'src/app/utils/types';

const DBKEYS = { THEME: 't-wheeliot', TOKEN: 'k-wheeliot', UID: 'u-wheeliot', CID: 'c-wheeliot', ROLE: 'r-wheeliot', EMAIL: 'e-wheeliot', TFID: 'tf-wheeliot',  SYNC: 's-status' };

export interface store {
	uid: number | string;
	companyId: string;
	tkn: string;
	role: UserRole;
	theme: AppTheme;
	layout: AppLayout;
	email: string;
	truckFitterId: string;
	syncronizationInProgress: string;
}

@Injectable({
	providedIn: 'root'
})
export class StoreService {

	// Local DB (Browser's Local Storage)
	private localdb: Storage = window.localStorage;

	// Initial state in BehaviorSubject's constructor
	private readonly subject: BehaviorSubject<store>;

	// Exposed observable$ store stream
	readonly state$: Observable<store>;

	// Getter of the last store value emitted
	private get store(): store {
		return this.subject.getValue();
	}

	// Push new state into the observable
	private set store(val: store) {
		this.subject.next(val);
	}

	private set theme(theme: AppTheme) {
		document.body.classList.remove('theme-dark', 'theme-light');
		document.body.classList.add(theme);
	}

	private get theme(): AppTheme {
		return document.body.classList.contains('theme-dark') ? 'theme-dark' : 'theme-light';
	}

	constructor(private breakpoints: BreakpointObserver) {
		// Initialize the app state
		const theme = (this.localdb.getItem(DBKEYS.THEME) as AppTheme) || this.theme;
		const uid = (this.localdb.getItem(DBKEYS.UID));
		const role = (this.localdb.getItem(DBKEYS.ROLE)) as UserRole;
		const tkn = (this.localdb.getItem(DBKEYS.TOKEN));
		const companyId = (this.localdb.getItem(DBKEYS.CID));
		const email = (this.localdb.getItem(DBKEYS.EMAIL));
		const truckFitterId = (this.localdb.getItem(DBKEYS.TFID));
		const syncronizationInProgress = (this.localdb.getItem(DBKEYS.SYNC));
		this.theme = theme;
		this.subject = new BehaviorSubject<store>({ uid, theme, role, tkn, companyId, layout: 'desktop', email, truckFitterId, syncronizationInProgress });
		this.state$ = this.subject.asObservable();

		breakpoints.observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait]).subscribe(result => {
			this.store = { ...this.store, layout: result.matches ? 'mobile' : 'desktop' };
		});
	}

	public toggleTheme(): AppTheme {
		const theme = this.theme === 'theme-light' ? 'theme-dark' : 'theme-light';
		this.theme = theme;
		this.localdb.setItem(DBKEYS.THEME, theme);
		this.store = { ...this.store, theme };
		return theme;
	}

	public storeUID(uid: string | number) {
		this.localdb.setItem(DBKEYS.UID, uid as string);
		this.store = { ...this.store, uid };
	}

	public removeUID() {
		this.localdb.removeItem(DBKEYS.UID);
		this.store = { ...this.store, uid: null };
	}

	public getUID() {
		return this.store.uid;
	}

	public storeToken(tkn: string) {
		this.localdb.setItem(DBKEYS.TOKEN, tkn);
		this.store = { ...this.store, tkn };
	}

	public removeToken() {
		this.localdb.removeItem(DBKEYS.TOKEN);
		this.store = { ...this.store, tkn: null };
	}

	public storeRole(role: UserRole) {
		this.localdb.setItem(DBKEYS.ROLE, role);
		this.store = { ...this.store, role };
	}

	public getRole() {
		return this.store.role;
	}

	public removeRole() {
		this.localdb.removeItem(DBKEYS.ROLE);
		this.store = { ...this.store, role: null };
	}

	public storeCompany(companyId: string) {
		this.localdb.setItem(DBKEYS.CID, `${companyId}`);
		this.store = { ...this.store, companyId };
	}

	public removeCompany() {
		this.localdb.removeItem(DBKEYS.CID);
		this.store = { ...this.store, companyId: null };
	}

	public getTruckFitter() {
		return this.store.truckFitterId;
	}

	public storeTruckFitter(truckFitterId: string) {
		this.localdb.setItem(DBKEYS.TFID, `${truckFitterId}`);
		this.store = { ...this.store, truckFitterId };
	}

	public removeTruckFitter() {
		this.localdb.removeItem(DBKEYS.TFID);
		this.store = { ...this.store, truckFitterId: null };
	}

	public storeEmail(email: string) {
		this.localdb.setItem(DBKEYS.EMAIL, `${email}`);
		this.store = { ...this.store, email };
	}

	public getEmail() {
		return this.store.email;
	}

	public removeEmail() {
		this.localdb.removeItem(DBKEYS.EMAIL);
		this.store = { ...this.store, email: null };
	}

	public storeSyncronizationInProgress(syncronizationInProgress: string) {
		this.localdb.setItem(DBKEYS.SYNC, syncronizationInProgress);
		this.store = { ...this.store, syncronizationInProgress }
	}

	public getSyncronizationInProgress() {
		return this.store.syncronizationInProgress;
	}
}
