import { Component, OnInit, HostListener } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { fadeBottom } from 'src/app/animations/fade';
import { AnimationState, OverlayData, OverlayComponentRef } from 'src/app/utils/types';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  animations: [fadeBottom]
})
export class ModalComponent implements OnInit {

  public animationState: AnimationState;

  constructor(readonly data: OverlayData, readonly ref: OverlayComponentRef) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode == 27) { // ESC = 27
      this.close();
    }
  }

  ngOnInit() {
    this.animationState = 'open';
  }

  close() {
    this.animationState = 'close';
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    if (this.data.text !== 'Contratto insoluto') {
      if (toState === 'close' && this.animationState === 'close') {
        this.ref.close();
      } else {
        this.ref.backdropClick.subscribe(_ => this.close());
      }
    }
  }
}
