import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { StoreService } from "../store/store.service";
import { AuthState, UserRole } from "src/app/utils/types";
import { CognitoUser } from "@aws-amplify/auth";
import { PATHS } from "src/app/app.routes";
import { OverlayService } from "../overlay/overlay.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AuthService implements CanActivate {
  private signedIn: boolean;
  private expTimeout: number;

  constructor(
    private router: Router,
    private amplify: AmplifyService,
    private store: StoreService,
    private overlay: OverlayService,
    private translateService: TranslateService
  ) {
    this.amplify.authStateChange$.subscribe(async (authState) => {
      if ((authState.state as AuthState) === "signIn" && authState.user) {
        this.signedIn = true;
        this.store.storeUID(authState.user);
      } else {
        const session = await this.getSession();
        this.signedIn = !!session;
      }
    });
  }

  public get user(): Promise<CognitoUser> {
    return this.amplify.auth().currentAuthenticatedUser();
  }

  public async canActivate(
    next?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ) {
    if (this.signedIn) {
      return true;
    } else {
      try {
        return !!(await this.getSession());
      } catch {
        this.router.navigate([PATHS.login]);
        return false;
      }
    }
  }

  public async setupTOTP(user: CognitoUser | any) {
    try {
      let code = await this.amplify.auth().setupTOTP(user);
      return (
        `otpauth://totp/${user.attributes.email}?secret=` +
        code +
        "&issuer=Wheeliot"
      );
    } catch (err) {
      console.log("error", err);
    }
  }

  public async verifyTotpToken(user: CognitoUser, challengeAnswer: string) {
    try {
      let verification = await this.amplify
        .auth()
        .verifyTotpToken(user, challengeAnswer);
      if (verification.Status === "SUCCESS") {
        this.amplify.auth().setPreferredMFA(user, "TOTP");
        return verification;
      }
    } catch (err) {
      console.log("Token is not verified");
      return err;
    }
  }

  public async confirmSignIn(user: CognitoUser, code: string) {
    try {
      const loggedUser = await this.amplify
        .auth()
        .confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");
      return loggedUser;
    } catch (err) {
      console.log("error", err);
    }
  }

  public async signIn(authData: {
    email: string;
    password: string;
  }): Promise<any> {
    try {
      let user = await this.amplify
        .auth()
        .signIn(authData.email, authData.password);
      user.getUserAttributes((error, attributes) => {
        if (!error) {
          attributes.find((a) => {
            if (a.getName() === "sub") {
              this.store.storeUID(a.getValue());
              return true;
            }
          });
        }
        // Record analytics (_userauth.sign_in) event
        //this.amplify.analytics().record({ name: 'userAttributes', attributes });
      });
      return user;
    } catch (err) {
      console.log(err.message);
      this.overlay.showToast({
        icon: "frown",
        color: "orange",
        text: `${this.translateService.instant("login.service-suspended")}`,
      });
    }
  }

  public async signOut(global: boolean = false): Promise<any> {
    return this.amplify.auth().signOut({ global });
  }

  /*
    This method will automatically refresh the accessToken and idToken
    if tokens are expired and a valid refreshToken presented. So you can
    use this method to refresh the session if needed.
  */
  public async getSession(): Promise<string> {
    try {
      const session = await this.amplify.auth().currentSession();
      let role: UserRole;
      if (session) {
        const helper = new JwtHelperService();
        const idTokenDecoded = helper.decodeToken(session.idToken.jwtToken);
        this.store.storeEmail(idTokenDecoded.email);
        const expDate = helper.getTokenExpirationDate(
          session.accessToken.jwtToken
        );
        this.renewToken(expDate);
        this.store.storeToken(session.accessToken.jwtToken);
        const decodedToken = helper.decodeToken(session.accessToken.jwtToken);
        if (
          decodedToken["cognito:groups"] &&
          decodedToken["cognito:groups"].length
        ) {
          (decodedToken["cognito:groups"] as Array<string>).find((r) => {
            if (!r.startsWith("checkme-")) {
              const info = r.split("_");
              role = info[0] as UserRole;
              this.store.storeRole(role);
              // if (role !== 'administrators') {
              //   this.store.storeCompany(info[1] ? info[1] : 'LGH');
              // }
              return true;
            } else {
              return false;
            }
          });
        } else {
          this.overlay.showToast({
            icon: "frown",
            text: this.translateService.instant("login.no-user-group"),
          });
        }
      }
      return session && role ? session.accessToken.jwtToken : null;
    } catch {
      return null;
    }
  }

  public renewToken(expDate: Date) {
    if (this.expTimeout) {
      clearTimeout(this.expTimeout);
    }
    const diff = expDate.getTime() - new Date().getTime();
    this.expTimeout = window.setTimeout(() => this.getSession(), diff);
  }

  public async forgotPassword(input: { email: string }): Promise<string> {
    try {
      const result = await this.amplify.auth().forgotPassword(input.email);
      this.overlay.showToast({
        icon: "envelope",
        text: this.translateService.instant("login.mail-sent"),
      });
      return result;
    } catch (err) {
      this.overlay.showToast({
        icon: "frown",
        color: "orange",
        text: this.translateService.instant("login.user-not-found"),
      });
      return new Promise((_, rej) => rej("not_found"));
    }
  }

  public async resetPassword(ipnut: {
    email: string;
    code: string;
    password: string;
  }): Promise<any> {
    try {
      const result = await this.amplify
        .auth()
        .forgotPasswordSubmit(ipnut.email, ipnut.code, ipnut.password);
      this.overlay.showToast({
        icon: "envelope",
        text: `${this.translateService.instant("login.edited-account")}!`,
      });
      return result;
    } catch (err) {
      this.overlay.showToast({ icon: "frown", text: err.message });
    }
  }

  public async updateAccount(input: { email: string; nickname: string }) {
    this.user
      .then((user: any) => {
        return this.amplify.auth().updateUserAttributes(user, {
          email: input.email,
          sub: input.nickname,
        });
      })
      .then((result) => {
        if (result === "SUCCESS") {
          this.overlay.showToast({
            icon: "check-circle",
            text: `${this.translateService.instant("login.updated-account")}!`,
            color: "green",
          });
        }
      })
      .catch((err) => {
        this.overlay.showToast({
          icon: "times-circle",
          text: err.message,
          color: "orange",
        });
      });
  }

  public changePassword(input: { oldPwd: string; newPwd: string }) {
    this.user
      .then((user: any) => {
        return this.amplify
          .auth()
          .changePassword(user, input.oldPwd, input.newPwd);
      })
      .then((data) => {
        if (data === "SUCCESS") {
          this.overlay.showToast({
            icon: "check-circle",
            text: `${this.translateService.instant("login.updated-password")}!`,
            color: "green",
          });
        }
      })
      .catch((err) => {
        this.overlay.showToast({
          icon: "times-circle",
          text: err.message,
          color: "orange",
        });
      });
  }

  public async completePassword(user: any, newPassword: string) {
    return this.amplify
      .auth()
      .completeNewPassword(user, newPassword, {})
      .then((data) => {
        if (!!data) {
          this.overlay.showToast({
            icon: "check-circle",
            text: this.translateService.instant("login.updated-password"),
            color: "green",
          });
          return true;
        }
      })
      .catch((err) => {
        this.overlay.showToast({
          icon: "times-circle",
          text: err.message,
          color: "orange",
        });
        return false;
      });
  }

  async createAccount(
    username: string,
    password: string,
    email: string,
    role: string,
    companyId: string
  ) {
    try {
      await this.amplify.auth().confirmSignUp({
        username,
        password,
        attributes: {
          email,
          companyId, // custom attribute
          role, // custom attribute
        },
      });
    } catch (error) {
      this.overlay.showToast({
        icon: "frown",
        text: this.translateService.instant("login.user-creation-error"),
        color: "orange",
      });
    }
  }
}
