export const ROUTES = {
	auth: { login: 'login' },
	authEDO: { loginEDO: 'loginEDO' },
	dashboard: { base: 'dashboard' },
	productionDashboard: { base: 'production-dashboard' },
	quicksight: { base: 'analisi' },
	customers: { base: 'customers' },
	box: { base: 'box' },
	audit: { base: 'audit' },
	kpi: { base: 'kpi' },
	map: { base: 'map', withPlate: ':plate'},
	path: { base: 'paths', detail: 'details', withPlate: ':plate', withDate: ':from/:to', withPosition: ':lat/:lon' },
	vehicles: { base: 'vehicles' },
	drivers: { base: 'drivers' },
	areas: { base: 'areas' },
	poi: { base: 'poi', path: 'path', new: ':lat/:lon' },
	users: { base: 'users', details: ':id' },
	logs: { base: 'logs' },
	video: { base: 'video' },
	telemetries: { base: 'telemetries', withPlate: ':plate', withDate: ':date' },
	diagnostics: { base: 'diagnostics' },
	notifications: { base: 'notifications' },
	anomalies: { base: 'anomalies' },
	commands: { base: 'commands' },
	serviceOrders: { base: 'service-orders' },
	reports: { base: 'reports' },
	routePlanning: { base: 'route-planning' },
	carSharing: { base: 'car-sharing' },
	kilometricRefund: { base: 'kilometric-refund' },
	administration: { base: 'administration' },
	edoDevices: { base: 'edo-devices' },
	dbAdministration: { base: 'db-administration' },
	telemetriesList: { base: 'telemetries-list'},
	remoteTelemetries: {base: 'remote-telemetries', withPlate: ':plate', withDates: ':startTimes/:endTimes' },
	remoteSessionsList: {base: 'remote-sessions-list'},
};

export const PATHS = {
	// Auth
	login: `/${ROUTES.auth.login}`,
	// AuthEDO
	loginEDO: `/${ROUTES.authEDO.loginEDO}`,
	// Dashboard
	dashboard: `/${ROUTES.dashboard.base}`,
	// Production Dashboard
	productionDashboard: `/${ROUTES.productionDashboard.base}`,
	// QuickSight
	quicksight: `/${ROUTES.quicksight.base}`,
	// Customers
	customers: `/${ROUTES.customers.base}`,
	// Smartbox
	box: `/${ROUTES.box.base}`,
	//  Audit
	audit: `/${ROUTES.audit.base}`,
	// Kpi
	kpi: `/${ROUTES.kpi.base}`,
	// Maps
	map: `/${ROUTES.map.base}`,
	// Paths
	path: `/${ROUTES.path.base}`,
	pathDetails: `/${ROUTES.path.base}/${ROUTES.path.detail}`,
	// Vehicles
	vehicles: `/${ROUTES.vehicles.base}`,
	// Drivers
	drivers: `/${ROUTES.drivers.base}`,
	// Areas
	areas: `/${ROUTES.areas.base}`,
	// Poi
	poi: `/${ROUTES.poi.base}`,
	poiPath: `/${ROUTES.poi.base}/${ROUTES.poi.path}`,
	poiNew: `/${ROUTES.poi.base}/${ROUTES.poi.new}`,
	// Users
	users: `/${ROUTES.users.base}`,
	userDetails: `/${ROUTES.users.base}/:id`,
	// Log
	log: `/${ROUTES.logs.base}`,
	// Video
	video: `/${ROUTES.video.base}`,
	// Telemetry
	telemetries: `/${ROUTES.telemetries.base}`,
	// Diagnostics
	diagnostics: `/${ROUTES.diagnostics.base}`,
	// Notifications
	notifications: `/${ROUTES.notifications.base}`,
	// Anomalies
	anomalies: `/${ROUTES.anomalies.base}`,
	// Commands
	commands: `/${ROUTES.commands.base}`,
	// Service orders
	serviceOrders: `/${ROUTES.serviceOrders.base}`,
	// Reports
	reports: `/${ROUTES.reports.base}`,
	// Route planning
	routePlanning: `/${ROUTES.routePlanning.base}`,
	// Car sharing
	carSharing: `/${ROUTES.carSharing.base}`,
	// Kilometric refund
	kilometricRefund: `/${ROUTES.kilometricRefund.base}`,
	// Administration
	administration: `/${ROUTES.administration.base}`,
	// Edo devices
	edoDevices: `/${ROUTES.edoDevices.base}`,
	// DB administration
	dbAdministration: `/${ROUTES.dbAdministration.base}`,
	// Telemetries list
	telemetriesList: `/${ROUTES.telemetriesList.base}`,
	// Remote telemetries
	remoteTelemetries: `/${ROUTES.remoteTelemetries.base}`, 
	// Remote sessions list
	remoteSessionsList: `/${ROUTES.remoteSessionsList.base}`
};