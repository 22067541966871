<div class="w-screen max-w-xs text-center flex flex-col justify-center">

  <div class="anim relative w-40 h-40 mx-auto">
    <div class="shape shape1 absolute h-16 w-16 rounded"></div>
    <div class="shape shape2 absolute h-16 w-16 rounded"></div>
    <div class="shape shape3 absolute h-16 w-16 rounded"></div>
    <div class="shape shape4 absolute h-16 w-16 rounded"></div>
  </div>

  <p class="text-primary font-bold text-xl mt-2">{{ data.text }}</p>
</div>