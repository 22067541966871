<ng-container *ngIf="isLoading">
	<div class="px-2 py-4">
		<p class="opacity-50 font-bold text-center">
			Caricamento <fa-icon icon="circle-notch" [spin]="true" class="ml-1"></fa-icon>
		</p>
 </div>
</ng-container>

<ng-container *ngIf="!isLoading && (!rows || rows.length <= 0)">
	<div class="px-2 py-4">
		<p class="opacity-50 font-bold text-center">
			Vuoto <fa-icon icon="frown" class="ml-2"></fa-icon>
		</p>
	</div>
</ng-container>

<ng-container *ngIf="!isLoading && rows && rows.length > 0">
	<div class="notif-c overflow-y-auto slimscroll">
		<div class="px-1" *ngFor="let r of rows; let index = index">
			<div class="flex flex-col p-1">
				<div class="flex justify-between truncate text-xss">
					<p class="bg-secondary text-secondary-contrast rounded font-bold px-1">{{ r.type }}</p>
					<p class="px-1">
						<span>{{ r.insertedAt*1000 | date: 'dd/MM/yyyy HH:mm' }}</span>
						<span *ngIf="r.status === 'unread'" class="cursor-pointer ml-1" (click)="markAsRead(r.insertedAt, r.id, index)"> <fa-icon icon="eye"></fa-icon></span>
					</p>
				</div>
				<p class="w-full truncate text-xs">{{ r.text }}</p>
			</div>
		</div>
	</div>
</ng-container>