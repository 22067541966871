<div class="p-1">
    <label  *ngIf="code" class="opacity-75 text-sm font-bold pb-1">{{ 'login.frame-qr-code' | translate }}</label>
    <div *ngIf="code" style="width: 256px; margin: 0 auto;">
        <qr-code [value]="code" size="256" errorCorrectionLevel="L"></qr-code>
    </div>
    <form [formGroup]="form" (keyup.enter)="chooseAction()">
        <div class="p-1 w-full flex flex-col">
            <label class="opacity-75 text-sm font-bold pb-1">{{ 'login.insert-code' | translate }}</label>
            <input type="text" formControlName="verificationCode" class="md:w-1/2"/>
        </div>
    </form>
    <div class="p-1 w-full flex justify-between">
        <div></div>
        <button class="primary" (click)="chooseAction()"><fa-icon icon="check" class="mr-1"></fa-icon>{{ 'login.verify' | translate }}</button>
    </div>
</div>